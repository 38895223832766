<style lang="scss" scoped>
.page-analysis-list {}
</style>

<template>
    <div class="page-analysis-list">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button class="mrgb5" type="primary" icon="el-icon-plus" @click="_edit()"
                        size="medium" v-if="isAnalysisReportCreate">生成</el-button>
                </div>
                <div class="fr">
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium"
                        :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" @change="_search"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        align="right">
                    </el-date-picker>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="关键字" clearable v-model="listQuery.keywords"
                        size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                    <el-button class="mrgb5" type="warning" size="medium" @click="openExportExcel()"><i class="fa fa-sign-out"></i> 导出</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" style="width: 100%" border size="mini" height="620px" @cell-dblclick="dbClick">
                <el-table-column label="日期(星期)" width="100" fixed>
                    <template slot-scope="scope">
                        {{ getFormatDate(scope.row.date, false, "YYYY-MM-DD") }}<br />
                        <el-tag type="parmary" size="mini">{{ getFormatWeek(scope.row.date) }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="综合信息" align="center">
                    <el-table-column label="运营地区" prop="area"></el-table-column>
                    <el-table-column label="天气" prop="weather"></el-table-column>
                    <el-table-column label="天气体验" prop="weatherExperience"></el-table-column>
                    <el-table-column label="平均温度(℃)">
                        <template slot-scope="scope">{{ scope.row.minTemperature }}~{{ scope.row.maxTemperature }}℃</template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="营收数据" align="center">
                    <el-table-column label="总收入(元)" prop="revenue.totalRevenue"></el-table-column>
                    <el-table-column label="钱包充值金额(元)" prop="revenue.walletRechargeAmount"></el-table-column>
                    <el-table-column label="骑行卡金额(元)" prop="revenue.rideCardAmount"></el-table-column>
                    <el-table-column label="会员卡金额(元)" prop="revenue.memberCardAmount"></el-table-column>
                    <el-table-column label="优惠卡金额(元)" prop="revenue.concessionCardAmount"></el-table-column>
                    <el-table-column label="诚信金额(元)" prop="revenue.honestyAmount"></el-table-column>
                    <el-table-column label="赠送金额(元)" prop="revenue.giftAmount"></el-table-column>
                    <el-table-column label="余额赠送(元)" prop="revenue.balanceGift"></el-table-column>
                    <el-table-column label="骑行卡赠送(元)" prop="revenue.rideCardGift"></el-table-column>
                    <el-table-column label="会员卡赠送(元)" prop="revenue.memberCardGift"></el-table-column>
                    <el-table-column label="优惠卡赠送(元)" prop="revenue.concessionCardGift"></el-table-column>
                    <el-table-column label="骑行费用(元)" prop="revenue.orderRideAmount"></el-table-column>
                    <el-table-column label="优惠金额(元)" prop="revenue.orderPreferentialAmount"></el-table-column>
                    <el-table-column label="订单罚金(元)" prop="revenue.orderPenaltyAmount"></el-table-column>
                    <el-table-column label="订单应收金额(元)" prop="revenue.receivableAmount"></el-table-column>
                    <el-table-column label="订单实际金额(元)" prop="revenue.orderAmount"></el-table-column>
                    <el-table-column label="订单欠款金额(元)" prop="revenue.orderArrears"></el-table-column>
                    <el-table-column label="钱包结算金额(元)" prop="revenue.orderWalletPayAmount"></el-table-column>
                    <el-table-column label="赠送余额结算(元)" prop="revenue.orderBalanceGiftPayAmount"></el-table-column>
                    <el-table-column label="历史订单钱包支付(元)" prop="revenue.historicalOrderWalletPayAmount"></el-table-column>
                    <el-table-column label="历史订单赠送余额支付(元)" prop="revenue.historicalOrderGiftPayAmount"></el-table-column>
                    <el-table-column label="骑行卡优惠(元)" prop="revenue.rideCardPreferential"></el-table-column>
                    <el-table-column label="折扣优惠(元)" prop="revenue.discountPreferential"></el-table-column>
                    <el-table-column label="优惠卡优惠(元)" prop="revenue.concessionCardPreferential"></el-table-column>
                    <el-table-column label="其它优惠(元)" prop="revenue.otherPreferential"></el-table-column>
                    <el-table-column label="钱包退款(元)" prop="revenue.walletRefund"></el-table-column>
                    <el-table-column label="充值退款(元)" prop="revenue.rechargeRefund"></el-table-column>
                    <el-table-column label="赠送退款(元)" prop="revenue.giftRefund"></el-table-column>
                </el-table-column>
                <el-table-column label="订单数据" align="center">
                    <el-table-column label="订单总数" prop="order.totalOrderCount"></el-table-column>
                    <el-table-column label="有效订单数" prop="order.validOrderCount"></el-table-column>
                    <el-table-column label="有效订单率(%)">
                        <template slot-scope="scope">{{ tranceRatios(scope.row, "order", "validOrderRate") }}%</template>
                    </el-table-column>
                    <el-table-column label="实收订单数" prop="order.paidOrderCount"></el-table-column>
                    <el-table-column label="实收订单率(%)">
                        <template slot-scope="scope">{{ tranceRatios(scope.row, "order", "paidOrderRate") }}%</template>
                    </el-table-column>
                    <el-table-column label="订单平均时长" prop="order.averageOrderDuration"></el-table-column>
                    <el-table-column label="订单平均金额(元)" prop="order.averageOrderAmount"></el-table-column>
                    <el-table-column label="骑行卡使用率(%)">
                        <template slot-scope="scope">{{ scope.row.order.rideCardUsageRate }}%</template>
                    </el-table-column>
                    <el-table-column label="会员卡使用率(%)">
                        <template slot-scope="scope">{{ scope.row.order.memberCardUsageRate }}%</template>
                    </el-table-column>
                    <el-table-column label="电单车低电丢单次数(次)" prop="order.lowPowerLostOrderCount"></el-table-column>
                    <el-table-column label="电单车运维丢单次数(次)" prop="order.maintenanceLostOrderCount"></el-table-column>
                    <el-table-column label="订单违停率(%)" prop="order.orderViolationRate"></el-table-column>
                    <el-table-column label="运营区内停车点外违停率(%)">
                        <template slot-scope="scope">{{ scope.row.order.saOrderViolationRate }}%</template>
                    </el-table-column>
                    <el-table-column label="运营区外违停率(%)">
                        <template slot-scope="scope">{{ scope.row.order.outOfSAOrderViolationRate }}%</template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="用户数据" align="center">
                    <el-table-column label="新增注册数(人)" prop="user.newRegistrationCount"></el-table-column>
                    <el-table-column label="未实名认证数(人)" prop="user.unauthenticatedCount"></el-table-column>
                    <el-table-column label="首骑用户数(人)" prop="user.firstRiderCount"></el-table-column>
                    <el-table-column label="有效骑行用户数(人)" prop="user.validRiderCount"></el-table-column>
                    <el-table-column label="每日新增用户骑行转化率(%)">
                        <template slot-scope="scope">{{ scope.row.user.dailyNewRiderConversionRate }}%</template>
                    </el-table-column>
                    <el-table-column label="总用户的骑行转化率(%)">
                        <template slot-scope="scope">{{ scope.row.user.totalRiderConversionRate }}%</template>
                    </el-table-column>
                    <el-table-column label="活跃用户(人)" prop="user.activeUserCount"></el-table-column>
                    <el-table-column label="高活用户(人)" prop="user.highActiveUserCount"></el-table-column>
                    <el-table-column label="新增复活用户数(人)" prop="user.newResurrectionCount"></el-table-column>
                    <el-table-column label="新增流失用户数(人)" prop="user.newLostCount"></el-table-column>
                </el-table-column>
                <el-table-column label="车辆数据" align="center">
                    <el-table-column label="投放车周转率(%)">
                        <template slot-scope="scope">{{ scope.row.vehicle.vehicleTurnoverRatio }}%</template>
                    </el-table-column>
                    <el-table-column label="可用车周转率(%)">
                        <template slot-scope="scope">{{ scope.row.vehicle.availableVehicleTurnoverRatio }}%</template>
                    </el-table-column>
                    <el-table-column label="DAB周转率(%)">
                        <template slot-scope="scope">{{ scope.row.vehicle.vehicleDabTurnoverRatio }}%</template>
                    </el-table-column>
                    <el-table-column label="总投放车(辆)" prop="vehicle.totalVehicleCount"></el-table-column>
                    <el-table-column label="可用车(辆)" prop="vehicle.availableVehicleCount"></el-table-column>
                    <el-table-column label="可用车占比(%)">
                        <template slot-scope="scope">{{ scope.row.vehicle.availableVehicleRatio }}%</template>
                    </el-table-column>
                    <el-table-column label="已报修(辆)" prop="vehicle.reportedVehicleCount"></el-table-column>
                    <el-table-column label="已报修占比(%)">
                        <template slot-scope="scope">{{ scope.row.vehicle.reportedVehicleRatio }}%</template>
                    </el-table-column>
                    <el-table-column label="低电量(辆)" prop="vehicle.lowBatteryVehicleCount"></el-table-column>
                    <el-table-column label="低电量占比(%)">
                        <template slot-scope="scope">{{ scope.row.vehicle.lowBatteryVehicleRatio }}%</template>
                    </el-table-column>
                    <el-table-column label="已下架(辆)" prop="vehicle.offlineVehicleCount"></el-table-column>
                    <el-table-column label="其它状态车辆(辆)" prop="vehicle.otherVehicleCount"></el-table-column>
                    <el-table-column label="告警车辆(辆)" prop="vehicle.warningVehicleCount"></el-table-column>
                    <el-table-column label="WAB车辆数(辆)" prop="vehicle.wabVehicleCount"></el-table-column>
                    <el-table-column label="电单车DAB(辆)" prop="vehicle.dabVehicleCount"></el-table-column>
                    <el-table-column label="未骑行2天未处理(辆)" prop="vehicle.day2IUVehicleCount"></el-table-column>
                    <el-table-column label="7日未骑行车辆数(辆)" prop="vehicle.day7IUVehicleCount"></el-table-column>
                    <el-table-column label="未上报且2天未处理(辆)" prop="vehicle.day2UUVehicleCount"></el-table-column>
                    <el-table-column label="故障车2天未处理(辆)" prop="vehicle.day2UFVehicleCount"></el-table-column>
                    <el-table-column label="运营范围外2天未处理(辆)" prop="vehicle.day2UOSAVehicleCount"></el-table-column>
                </el-table-column>
                <el-table-column label="运维数据" align="center">
                    <el-table-column label="换电量(次)" prop="operation.batterySwapCount"></el-table-column>
                    <el-table-column label="挪车量(次)" prop="operation.moveCount"></el-table-column>
                    <el-table-column label="维修量(次)" prop="operation.maintenanceCount"></el-table-column>
                </el-table-column>
                
                <el-table-column label="操作" align="center" width="100" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="_query(scope.row)">查看详情</el-dropdown-item>
                                <el-dropdown-item @click.native="_edit(scope.row)" v-if="isAnalysisReportUpdate">编辑</el-dropdown-item>
                                <el-tooltip effect="dark" content="错误的报表可以先删除，然后再清空导入的数据。" placement="bottom">
                                    <el-dropdown-item @click.native="_delete(scope.row)" v-if="isAnalysisReportDelete">删除</el-dropdown-item>
                                </el-tooltip>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <!--详情-->
        <detail :ref="refDetail"></detail>
        <!--编辑-->
        <edit :ref="refEdit" @refreshData="getListByDialog"></edit>
        <!--导出excel-->
        <export-to-excel :ref="refExportToExcel"></export-to-excel>
    </div>
</template>

<script>
import permission from "@/mixins/permission"
import * as funReport from "@/api/report"
import detail from "./components/detail"
import edit from "./components/edit"
import ExportToExcel from "@/components/Excels/JsonToExcel"
import moment from "moment"
import { AnalysisReports } from "@/mixins/commonPageExport"
export default {
    name: "pageAnalysisList",
    mixins: [permission],
    components: { detail, edit, ExportToExcel },
    data() {
        let that = this
        return {
            refDetail: "refAnalysisToDetail",
            refEdit: "refAnalysisToEdit",
            refExportToExcel: "refAnalysisToExportExcel",
            tableData: [],
            total: null,
            listQuery: {
                area: "",
                type: 0,
                beginDate: "",
                endDate: "",
                keywords: null,
                page: 1,
                size: window.$common.appPageSize
            },
            isPagination: false,
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            }
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this._search()
            })
        }
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        setQueryTime(timeArr) {
            this.listQuery.beginDate = timeArr[0] || ""
            this.listQuery.endDate = timeArr[1] || ""
        },
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.beginDate = psTime
            this.listQuery.endDate = peTime
            this.selectedTime = [this.listQuery.beginDate, this.listQuery.endDate]
        },
        getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            funReport.GetList(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items.map(x => {
                        // 骑行卡使用率
                        x.order.rideCardUsageRate = window.$util.operationMulti(x.order.rideCardUsageRate, 100)
                        // 会员卡使用率
                        x.order.memberCardUsageRate = window.$util.operationMulti(x.order.memberCardUsageRate, 100)
                        // 订单违停率
                        x.order.orderViolationRate = window.$util.operationMulti(x.order.orderViolationRate, 100)
                        // 运营区内订单违停率
                        x.order.saOrderViolationRate = window.$util.operationMulti(x.order.saOrderViolationRate, 100)
                        // 运营区外订单违停率
                        x.order.outOfSAOrderViolationRate = window.$util.operationMulti(x.order.outOfSAOrderViolationRate, 100)
                        // 每日新增用户骑行转化率
                        x.user.dailyNewRiderConversionRate = window.$util.operationMulti(x.user.dailyNewRiderConversionRate, 100)
                        // 总用户的骑行转化率
                        x.user.totalRiderConversionRate = window.$util.operationMulti(x.user.totalRiderConversionRate, 100)
                        // 投放车周转率
                        x.vehicle.vehicleTurnoverRatio = window.$util.operationMulti(x.vehicle.vehicleTurnoverRatio, 100)
                        // 可用车周转率
                        x.vehicle.availableVehicleTurnoverRatio = window.$util.operationMulti(x.vehicle.availableVehicleTurnoverRatio, 100)
                        // 电单车DAB周转率
                        x.vehicle.vehicleDabTurnoverRatio = window.$util.operationMulti(x.vehicle.vehicleDabTurnoverRatio, 100)
                        // 可用车辆占比
                        x.vehicle.availableVehicleRatio = (x.vehicle.availableVehicleCount || 0) / x.vehicle.totalVehicleCount
                        x.vehicle.availableVehicleRatio = window.$util.toFixed(x.vehicle.availableVehicleRatio, 4)
                        x.vehicle.availableVehicleRatio = window.$util.operationMulti(x.vehicle.availableVehicleRatio, 100)
                        // 报修车辆占比
                        x.vehicle.reportedVehicleRatio = (x.vehicle.reportedVehicleCount || 0) / x.vehicle.totalVehicleCount
                        x.vehicle.reportedVehicleRatio = window.$util.toFixed(x.vehicle.reportedVehicleRatio, 4)
                        x.vehicle.reportedVehicleRatio = window.$util.operationMulti(x.vehicle.reportedVehicleRatio, 100)
                        // 低电量车辆占比
                        x.vehicle.lowBatteryVehicleRatio = (x.vehicle.lowBatteryVehicleCount || 0) / x.vehicle.totalVehicleCount
                        x.vehicle.lowBatteryVehicleRatio = window.$util.toFixed(x.vehicle.lowBatteryVehicleRatio, 4)
                        x.vehicle.lowBatteryVehicleRatio = window.$util.operationMulti(x.vehicle.lowBatteryVehicleRatio, 100)
                        return x
                    })
                } else {
                    this.tableData = []
                }
            })
        },
        // 判断是创建还是更新，执行刷新操作
        getListByDialog(type) {
            if (type === 1) {
                this.getDataList()
            } else {
                this._search()
            }
        },
        tranceRatios(row, param1, param2) {
            let val = window.$util.operationMulti(row[param1][param2], 100)
            return val ? window.$util.toFixed(val) : ""
        },
        dbClick(row) {
            this._query(row)
        },
        _query(row) {
            this.$refs[this.refDetail].open(row)
        },
        _edit(row) {
            this.$refs[this.refEdit].open(row)
        },
        _delete(row) {
            // 删除
            this.confirm("确定要删除当前数据报表吗？").then(()=>{
                window.$common.fullLoading()
                funReport.Delete(row.id).then(() => {
                    this.getDataList()
                })
            })
        },
        _search() { 
            this.listQuery.page = 1
            this.getDataList()
        },
        // 导出excel
        openExportExcel() {
            if (this.total > this.maxExportCount) {
                this.warningMsg("不允许导出超过2万条数据，如有需要，请联系管理员处理!")
                return false
            }
            let searchs = JSON.parse(JSON.stringify(this.listQuery))
            searchs.page = 1
            searchs.size = this.maxExportCount
            window.$common.fullLoading()
            funReport.GetList(searchs).then(res => {
                if (res && res.items && res.items.length > 0) {
                    let tableDatas = res.items.map(x => {
                        let perUnit = "%"
                        x.date = moment(x.date).format("YYYY-MM-DD")
                        x.week = this.getFormatWeek(x.date)
                        x.rangeTemperature = `${x.minTemperature}~${x.maxTemperature}℃`
                        for (let i in x.revenue) {
                            x["revenue." + i] = x.revenue[i]
                        }
                        for (let i in x.order) {
                            x["order." + i] = x.order[i]
                        }
                        for (let i in x.user) {
                            x["user." + i] = x.user[i]
                        }
                        for (let i in x.vehicle) {
                            x["vehicle." + i] = x.vehicle[i]
                        }
                        for (let i in x.operation) {
                            x["operation." + i] = x.operation[i]
                        }
                        // 有效订单率
                        x["order.validOrderRate"] = this.tranceRatios(x, "order", "validOrderRate") + perUnit
                        // 实收订单率
                        x["order.paidOrderRate"] = this.tranceRatios(x, "order", "paidOrderRate") + perUnit
                        // 骑行卡使用率
                        x["order.rideCardUsageRate"] = window.$util.operationMulti(x.order.rideCardUsageRate, 100) + perUnit
                        // 会员卡使用率
                        x["order.memberCardUsageRate"] = window.$util.operationMulti(x.order.memberCardUsageRate, 100) + perUnit
                        // 订单违停率
                        x["order.orderViolationRate"] = window.$util.operationMulti(x.order.orderViolationRate, 100) + perUnit
                        // 运营区内订单违停率
                        x["order.saOrderViolationRate"] = window.$util.operationMulti(x.order.saOrderViolationRate, 100) + perUnit
                        // 运营区外订单违停率
                        x["order.outOfSAOrderViolationRate"] = window.$util.operationMulti(x.order.outOfSAOrderViolationRate, 100) + perUnit
                        // 每日新增用户骑行转化率
                        x["user.dailyNewRiderConversionRate"] = window.$util.operationMulti(x.user.dailyNewRiderConversionRate, 100) + perUnit
                        // 总用户的骑行转化率
                        x["user.totalRiderConversionRate"] = window.$util.operationMulti(x.user.totalRiderConversionRate, 100) + perUnit
                        // 投放车周转率
                        x["vehicle.vehicleTurnoverRatio"] = window.$util.operationMulti(x.vehicle.vehicleTurnoverRatio, 100) + perUnit
                        // 可用车周转率
                        x["vehicle.availableVehicleTurnoverRatio"] = window.$util.operationMulti(x.vehicle.availableVehicleTurnoverRatio, 100) + perUnit
                        // 电单车DAB周转率
                        x["vehicle.vehicleDabTurnoverRatio"] = window.$util.operationMulti(x.vehicle.vehicleDabTurnoverRatio, 100) + perUnit
                        // 可用车辆占比
                        x["vehicle.availableVehicleRatio"] = (x.vehicle.availableVehicleCount || 0) / x.vehicle.totalVehicleCount
                        x["vehicle.availableVehicleRatio"] = window.$util.toFixed(x["vehicle.availableVehicleRatio"], 4)
                        x["vehicle.availableVehicleRatio"] = window.$util.operationMulti(x["vehicle.availableVehicleRatio"], 100) + perUnit
                        // 报修车辆占比
                        x["vehicle.reportedVehicleRatio"] = (x.vehicle.reportedVehicleCount || 0) / x.vehicle.totalVehicleCount
                        x["vehicle.reportedVehicleRatio"] = window.$util.toFixed(x["vehicle.reportedVehicleRatio"], 4)
                        x["vehicle.reportedVehicleRatio"] = window.$util.operationMulti(x["vehicle.reportedVehicleRatio"], 100) + perUnit
                        // 低电量车辆占比
                        x["vehicle.lowBatteryVehicleRatio"] = (x.vehicle.lowBatteryVehicleCount || 0) / x.vehicle.totalVehicleCount
                        x["vehicle.lowBatteryVehicleRatio"] = window.$util.toFixed(x["vehicle.lowBatteryVehicleRatio"], 4)
                        x["vehicle.lowBatteryVehicleRatio"] = window.$util.operationMulti(x["vehicle.lowBatteryVehicleRatio"], 100) + perUnit
                        return x
                    })
                    AnalysisReports(`${this.$store.getters.serviceArea}每日数据统计(${moment().format("YYYY-MM-DD")}).xlsx`, tableDatas)
                    // this.exportExcelData(tableDatas)
                } else {
                    window.$common.closeFullLoading()
                    setTimeout(() => {
                        this.alert("没有需要导出的数据!")
                    }, 10)
                }
            }).catch(() => {
                console.log("export error")
            })
        },
        exportExcelData(tableDatas) {
            const multiHeader = [["日期", "综合信息", "", "", "", 
                            "营收数据", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", 
                            "订单数据", "", "", "", "", "", "", "", "", "", "", "", "", "", 
                            "用户数据", "", "", "", "", "", "", "", "", "", 
                            "车辆数据", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", 
                            "运维数据", "", ""]]
            const tHeader = ["", "运营地区", "天气", "天气体验", "平均温度(℃)", 
                            "总收入(元)", "钱包充值金额(元)", "骑行卡金额(元)", "会员卡金额(元)", "优惠卡金额(元)", "诚信金额(元)", "赠送金额(元)", "余额赠送(元)", "骑行卡赠送(元)", "会员卡赠送(元)", "优惠卡赠送(元)", "骑行费用(元)", "优惠金额(元)", "订单罚金(元)", "订单实际金额(元)", "钱包结算金额(元)", "赠送余额结算(元)", "骑行卡优惠(元)", "折扣优惠(元)", "优惠卡优惠(元)", "其它优惠(元)", "钱包退款(元)", "充值退款(元)", "赠送退款(元)",
                            "订单总数", "有效订单数", "有效订单率(%)", "实收订单数", "实收订单率(%)", "订单平均时长", "订单平均金额(元)", "骑行卡使用率(%)", "会员卡使用率(%)", "电单车低电丢单次数(次)", "电单车运维丢单次数(次)", "订单违停率(%)", "运营区内停车点外违停率(%)", "运营区外违停率(%)", 
                            "新增注册数(人)", "未实名认证数(人)", "首骑用户数(人)", "有效骑行用户数(人)", "每日新增用户骑行转化率(%)", "总用户的骑行转化率(%)", "活跃用户(人)", "高活用户(人)", "新增复活用户数(人)", "新增流失用户数(人)", 
                            "投放车周转率(%)", "可用车周转率(%)", "DAB周转率(%)", "总投放车(辆)", "可用车(辆)", "可用车占比(%)", "已报修(辆)", "已报修占比(%)", "低电量(辆)", "低电量占比(%)", "已下架(辆)", "其他状态车辆(辆)", "告警车辆(辆)", "WAB车辆数(辆)", "电单车DAB(辆)", "未骑行2天未处理(辆)", "7日未骑行车辆数(辆)", "未上报且2天未处理(辆)", "故障车2天未处理(辆)", "运营范围外2天未处理(辆)", 
                            "换电量(次)", "挪车量(次)", "维修量(次)"]
            const filterVal = ["date", "area", "weather", "weatherExperience", "rangeTemperature", 
                            "revenue.totalRevenue", "revenue.walletRechargeAmount", "revenue.rideCardAmount", "revenue.memberCardAmount", "revenue.concessionCardAmount", "revenue.honestyAmount", "revenue.giftAmount", "revenue.balanceGift", "revenue.rideCardGift", "revenue.memberCardGift", "revenue.concessionCardGift", "revenue.orderRideAmount", "revenue.orderPreferentialAmount", "revenue.orderPenaltyAmount", "revenue.orderAmount", "revenue.orderWalletPayAmount", "revenue.orderBalanceGiftPayAmount", "revenue.rideCardPreferential", "revenue.discountPreferential", "revenue.concessionCardPreferential", "revenue.otherPreferential", "revenue.walletRefund", "revenue.rechargeRefund", "revenue.giftRefund", 
                            "order.totalOrderCount", "order.validOrderCount", "order.validOrderRate", "order.paidOrderCount", "order.paidOrderRate", "order.averageOrderDuration", "order.averageOrderAmount", "order.rideCardUsageRate", "order.memberCardUsageRate", "order.lowPowerLostOrderCount", "order.maintenanceLostOrderCount", "order.orderViolationRate", "order.saOrderViolationRate", "order.outOfSAOrderViolationRate", 
                            "user.newRegistrationCount", "user.unauthenticatedCount", "user.firstRiderCount", "user.validRiderCount", "user.dailyNewRiderConversionRate", "user.totalRiderConversionRate", "user.activeUserCount", "user.highActiveUserCount", "user.newResurrectionCount", "user.newLostCount", 
                            "vehicle.vehicleTurnoverRatio", "vehicle.availableVehicleTurnoverRatio", "vehicle.vehicleDabTurnoverRatio", "vehicle.totalVehicleCount", "vehicle.availableVehicleCount", "vehicle.availableVehicleRatio", "vehicle.reportedVehicleCount", "vehicle.reportedVehicleRatio", "vehicle.lowBatteryVehicleCount", "vehicle.lowBatteryVehicleRatio", "vehicle.offlineVehicleCount", "vehicle.otherVehicleCount", "vehicle.warningVehicleCount", "vehicle.wabVehicleCount", "vehicle.dabVehicleCount", "vehicle.day2IUVehicleCount", "vehicle.day7IUVehicleCount", "vehicle.day2UUVehicleCount", "vehicle.day2UFVehicleCount", "vehicle.day2UOSAVehicleCount", 
                            "operation.batterySwapCount", "operation.moveCount", "operation.maintenanceCount"]
            const merges = ["A1:A2", "B1:E1", "F1:AC1", "AD1:AQ1", "AR1:BA1", "BB1:BU1", "BV1:BX1"]
            this.exportCommonExcel("数据报表", tableDatas, tHeader, filterVal, merges, "", multiHeader)
        }
    }
}
</script>