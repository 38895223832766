<style lang="scss" scoped>
/deep/ .comp-analysis-edit-dialog {
    margin-top: 5vh !important;
    width: 88%;
    .sync-refresh-btn {
        margin: 2px 0 0 5px;
        padding: 5px;
        display: inline-flex;
        border: 1px solid #b3d8ff;
        color: #409eff;
        background: #ecf5ff;
        border-radius: 4px;
        cursor: pointer;
    }
    .sync-refresh-btn:hover {
        background-color: #409eff;
        color: #ffffff;
    }

    .sync-refresh-btn-danger {
        margin: 2px 0 0 5px;
        padding: 5px;
        display: inline-flex;
        border: 1px solid #fbc4c4;
        color: #F56C6C;
        background: #fef0f0;
        border-radius: 4px;
        cursor: pointer;
    }
    .sync-refresh-btn-danger:hover {
        background-color: #F56C6C;
        color: #ffffff;
    }
}
</style>

<template>
    <div class="comp-analysis-edit">
        <el-dialog title="编辑数据" custom-class="c-el-dialog comp-analysis-edit-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="232px" size="small">
                <el-card>
                    <div slot="header">
                        <span>综合信息</span>
                    </div>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item label="日期" prop="date">
                                <el-date-picker v-model="dialogData.date" type="date" placeholder="选择日期" @change="selectAndComputed"
                                    :picker-options="pickerOptions"></el-date-picker>
                                <el-tooltip placement="top-start" effect="light">
                                    <div slot="content">
                                        <div class="col_info">点击打开同步数据的界面，需手动选择，并保存生效</div>
                                        <div class="col_primary">蓝色按钮：默认没有需要同步的数据</div>
                                        <div class="col_danger">红色按钮：默认有需要同步的数据</div>
                                    </div>
                                    <span class="sync-refresh-btn" :class="{ 'sync-refresh-btn-danger': isShowSyncBtn }" v-if="dialogType == createOrUpdate[1]" @click="_updateAnsyc()">
                                        <i class="el-icon-refresh"></i>
                                    </span>
                                </el-tooltip>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item label="投放地区" prop="area">
                                <el-select class="c-pw100" v-model="dialogData.area" disabled placeholder="请选择投放地区" @change="selectAndComputed">
                                    <el-option v-for="area in $store.getters.serviceAreas" :key="area.id" :label="area.name" :value="area.name"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item label="天气" prop="weather">
                                <el-select class="c-pw100" v-model="dialogData.weather" placeholder="请选择天气情况" filterable clearable>
                                    <el-option v-for="opt in weatherInfos" :key="opt" :label="opt" :value="opt"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item label="天气体验" prop="weatherExperience">
                                <el-select class="c-pw100" v-model="dialogData.weatherExperience" placeholder="请选择天气体验感受" filterable clearable>
                                    <el-option v-for="opt in expWeathers" :key="opt" :label="opt" :value="opt"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item label="最低温度" prop="minTemperature">
                                <el-input type="number" v-model="dialogData.minTemperature" :min="-50" :max="50" placeholder="请输入最低温度">
                                    <template slot="append">℃</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item label="最高温度" prop="maxTemperature">
                                <el-input type="number" v-model="dialogData.maxTemperature" :min="-50" :max="50" placeholder="请输入最高温度">
                                    <template slot="append">℃</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item label="温度范围">
                                <el-input v-model="rangeTemperature" placeholder="自动显示温度范围" disabled>
                                    <template slot="append">℃</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-card>
                <el-tabs class="mrgt20" v-model="activeName" type="border-card">
                    <el-tab-pane :label="tagItem.revenue" name="revenue" v-if="dialogData.revenue">
                        <el-row :gutter="10">
                            <el-card>
                                <div>
                                    <el-divider content-position="left">营收</el-divider>
                                    <!--<el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="总收入" name="revenue.totalRevenue" prop="revenue.totalRevenue">
                                            <el-tooltip content="单日电车总收入（净入账=钱包充值-钱包退款+骑行卡收入+会员卡收入+优惠卡收入+诚信金收入）" placement="top-start">
                                                <el-input type="number" v-model="dialogData.revenue.totalRevenue" :min="0" :step="0.01" placeholder="单日电车总收入">
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>-->
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="钱包充值金额" prop="revenue.walletRechargeAmount">
                                            <el-input type="number" v-model="dialogData.revenue.walletRechargeAmount" :min="0" :step="0.01" placeholder="请输入钱包充值金额">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="骑行卡金额" prop="revenue.rideCardAmount">
                                            <el-input type="number" v-model="dialogData.revenue.rideCardAmount" :min="0" :step="0.01" placeholder="请输入骑行卡金额">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="会员卡金额" prop="revenue.memberCardAmount">
                                            <el-input type="number" v-model="dialogData.revenue.memberCardAmount" :min="0" :step="0.01" placeholder="请输入会员卡金额">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="优惠卡金额" prop="revenue.concessionCardAmount">
                                            <el-input type="number" v-model="dialogData.revenue.concessionCardAmount" :min="0" :step="0.01" placeholder="请输入优惠卡金额">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="诚信金额" prop="revenue.honestyAmount">
                                            <el-input type="number" v-model="dialogData.revenue.honestyAmount" :min="0" :step="0.01" placeholder="请输入诚信金额">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="钱包退款" prop="revenue.walletRefund">
                                            <el-tooltip content="钱包退款：从钱包退还给用户" placement="top-start">
                                                <el-input type="number" v-model="dialogData.revenue.walletRefund" :min="0" :step="0.01" placeholder="请输入钱包退款">
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="充值退款" prop="revenue.rechargeRefund">
                                            <el-tooltip content="充值退款：退回余额" placement="top-start">
                                                <el-input type="number" v-model="dialogData.revenue.rechargeRefund" :min="0" :step="0.01" placeholder="请输入充值退款">
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                </div>
                            </el-card>

                            <el-card class="mrgt20">
                                <div>
                                    <el-divider content-position="left">实收</el-divider>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="骑行费用" prop="revenue.orderRideAmount">
                                            <el-input type="number" v-model="dialogData.revenue.orderRideAmount" :min="0" :step="0.01" placeholder="请输入骑行费用">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="优惠金额" prop="revenue.orderPreferentialAmount">
                                            <el-tooltip content="优惠金额=骑行卡优惠+折扣优惠+优惠卡优惠+其它优惠" placement="top-start">
                                                <el-input type="number" v-model="dialogData.revenue.orderPreferentialAmount" :min="0" :step="0.01" placeholder="请输入优惠金额">
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="订单罚金" prop="revenue.orderPenaltyAmount">
                                            <el-input type="number" v-model="dialogData.revenue.orderPenaltyAmount" :min="0" :step="0.01" placeholder="请输入订单罚金">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="订单实际金额" prop="revenue.orderAmount">
                                            <el-tooltip content="订单实际金额(骑行金额-优惠金额+罚金)" placement="top-start">
                                                <el-input type="number" v-model="dialogData.revenue.orderAmount" :min="0" :step="0.01" placeholder="请输入订单实际金额">
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="钱包结算金额" prop="revenue.orderWalletPayAmount">
                                            <el-input type="number" v-model="dialogData.revenue.orderWalletPayAmount" :min="0" :step="0.01" placeholder="请输入钱包结算金额">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="赠送余额结算" prop="revenue.orderBalanceGiftPayAmount">
                                            <el-input type="number" v-model="dialogData.revenue.orderBalanceGiftPayAmount" :min="0" :step="0.01" placeholder="请输入赠送余额结算">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="骑行卡优惠" prop="revenue.rideCardPreferential">
                                            <el-input type="number" v-model="dialogData.revenue.rideCardPreferential" :min="0" :step="0.01" placeholder="请输入骑行卡优惠">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="折扣优惠" prop="revenue.discountPreferential">
                                            <el-input type="number" v-model="dialogData.revenue.discountPreferential" :min="0" :step="0.01" placeholder="请输入折扣优惠">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="优惠卡优惠" prop="revenue.concessionCardPreferential">
                                            <el-input type="number" v-model="dialogData.revenue.concessionCardPreferential" :min="0" :step="0.01" placeholder="请输入优惠卡优惠">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="其它优惠" prop="revenue.otherPreferential">
                                            <el-tooltip content="其它优惠=优惠金额-骑行卡优惠-折扣优惠-优惠卡优惠" placement="top-start">
                                                <el-input type="number" v-model="dialogData.revenue.otherPreferential" :min="0" :step="0.01" placeholder="请输入其它优惠">
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                </div>
                            </el-card>

                            <el-card class="mrgt20">
                                <div>
                                    <el-divider content-position="left">订单收支</el-divider>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="订单欠款金额" prop="revenue.orderArrears">
                                            <el-tooltip content="截至到订单表格导入时，还未支付的金额" placement="top-start">
                                                <el-input type="number" v-model="dialogData.revenue.orderArrears" :min="0" :step="0.01" placeholder="请输入订单欠款金额">
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="历史订单钱包支付" prop="revenue.historicalOrderWalletPayAmount">
                                            <el-tooltip content="历史订单中使用钱包支付的金额" placement="top-start">
                                                <el-input type="number" v-model="dialogData.revenue.historicalOrderWalletPayAmount" :min="0" :step="0.01" placeholder="请输入历史订单钱包支付">
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="历史订单赠送余额支付" prop="revenue.historicalOrderGiftPayAmount">
                                            <el-tooltip content="历史订单中使用赠送余额支付的金额" placement="top-start">
                                                <el-input type="number" v-model="dialogData.revenue.historicalOrderGiftPayAmount" :min="0" :step="0.01" placeholder="请输入历史订单赠送余额支付">
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                </div>
                            </el-card>

                            <el-card class="mrgt20">
                                <div>
                                    <el-divider content-position="left">赠送</el-divider>
                                    <!--<el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="赠送金额" prop="revenue.giftAmount">
                                            <el-tooltip content="赠送金额=余额赠送+骑行卡赠送+会员卡赠送+优惠卡赠送" placement="top-start">
                                                <el-input type="number" v-model="dialogData.revenue.giftAmount" :min="0" :step="0.01" placeholder="请输入赠送金额">
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>-->
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="余额赠送" prop="revenue.balanceGift">
                                            <el-input type="number" v-model="dialogData.revenue.balanceGift" :min="0" :step="0.01" placeholder="请输入余额赠送">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="骑行卡赠送" prop="revenue.rideCardGift">
                                            <el-input type="number" v-model="dialogData.revenue.rideCardGift" :min="0" :step="0.01" placeholder="请输入骑行卡赠送">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="会员卡赠送" prop="revenue.memberCardGift">
                                            <el-input type="number" v-model="dialogData.revenue.memberCardGift" :min="0" :step="0.01" placeholder="请输入会员卡赠送">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="优惠卡赠送" prop="revenue.concessionCardGift">
                                            <el-input type="number" v-model="dialogData.revenue.concessionCardGift" :min="0" :step="0.01" placeholder="请输入优惠卡赠送">
                                                <template slot="append">元</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="赠送退款" prop="revenue.giftRefund">
                                            <el-tooltip content="赠送退款：退回到赠送余额" placement="top-start">
                                                <el-input type="number" v-model="dialogData.revenue.giftRefund" :min="0" :step="0.01" placeholder="请输入赠送退款">
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                </div>
                            </el-card>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane :label="tagItem.order" name="order" v-if="dialogData.order">
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="订单总数" prop="order.totalOrderCount">
                                    <el-input type="number" v-model="dialogData.order.totalOrderCount" :min="0" placeholder="当天完成的所有订单"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="有效订单数" prop="order.validOrderCount">
                                    <el-input type="number" v-model="dialogData.order.validOrderCount" :min="0" :max="dialogData.order.totalOrderCount" placeholder="骑行金额大于0的订单数"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="有效订单率" prop="validOrderRate">
                                    <el-input type="number" v-model="validOrderRate" :min="0" :step="0.01" disabled placeholder="有效订单占总订单的比例">
                                        <template slot="append">%</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="实收订单数" prop="order.paidOrderCount">
                                    <el-input type="number" v-model="dialogData.order.paidOrderCount" :min="0" :max="dialogData.order.totalOrderCount" placeholder="有实际收入的订单数"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="实收订单率" prop="paidOrderRate">
                                    <el-tooltip content="已完成骑行并正常支付的订单比例（实收/有效）" placement="top-start">
                                        <el-input type="number" v-model="paidOrderRate" :min="0" :step="0.01" disabled placeholder="已完成骑行并正常支付的订单比例">
                                            <template slot="append">%</template>
                                        </el-input>
                                    </el-tooltip>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="订单平均时长" prop="order.averageOrderDuration">
                                    <el-tooltip content="有效订单数量平均时间（排除超过1小时的长订单，计算所有订单的平均时间）" placement="top-start">
                                        <el-time-picker style="width: 100%;" v-model="dialogData.order.averageOrderDuration" placeholder="有效订单数量平均时间"></el-time-picker>
                                    </el-tooltip>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="订单平均金额" prop="order.averageOrderAmount">
                                    <el-tooltip content="实收订单数量平均金额，实收金额/实收订单" placement="top-start">
                                        <el-input type="number" v-model="dialogData.order.averageOrderAmount" :min="0" :step="0.01" placeholder="实收订单数量平均金额">
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-tooltip>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="骑行卡使用率" prop="order.rideCardUsageRate">
                                    <el-input type="number" v-model="dialogData.order.rideCardUsageRate" :min="0" :step="0.01" placeholder="使用骑行卡结算/总有效订单">
                                        <template slot="append">%</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="会员卡使用率" prop="order.memberCardUsageRate">
                                    <el-input type="number" v-model="dialogData.order.memberCardUsageRate" :min="0" :step="0.01" placeholder="使用会员结算/总有效订单">
                                        <template slot="append">%</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="电单车低电丢单次数" prop="order.lowPowerLostOrderCount">
                                    <el-input type="number" v-model="dialogData.order.lowPowerLostOrderCount" :min="0" placeholder="扫码后显示低电数量">
                                        <template slot="append">次</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="电单车运维丢单次数" prop="order.maintenanceLostOrderCount">
                                    <el-input type="number" v-model="dialogData.order.maintenanceLostOrderCount" :min="0" placeholder="扫码后显示运维中">
                                        <template slot="append">次</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="有效骑行订单违停率" prop="order.orderViolationRate">
                                    <el-input type="number" v-model="dialogData.order.orderViolationRate" :min="0" :step="0.01" placeholder="点位外还车订单/总订单">
                                        <template slot="append">%</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="运营区内停车点外违停率" prop="order.saOrderViolationRate">
                                    <el-tooltip content="点位外还车订单/（总订单-运营区外还车订单）" placement="top-start">
                                        <el-input type="number" v-model="dialogData.order.saOrderViolationRate" :min="0" :step="0.01" placeholder="有效骑行运营区内停车点外违停率">
                                            <template slot="append">%</template>
                                        </el-input>
                                    </el-tooltip>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="运营区外违停率" prop="order.outOfSAOrderViolationRate">
                                    <el-tooltip content="运营区外还车订单/（总订单-运营区内点位外还车订单）" placement="top-start">
                                        <el-input type="number" v-model="dialogData.order.outOfSAOrderViolationRate" :min="0" :step="0.01" placeholder="有效骑行运营区外违停率">
                                            <template slot="append">%</template>
                                        </el-input>
                                    </el-tooltip>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane :label="tagItem.user" name="user" v-if="dialogData.user">
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="新增注册数" prop="user.newRegistrationCount">
                                    <el-input type="number" v-model="dialogData.user.newRegistrationCount" :min="0" placeholder="新增注册用户数量">
                                        <template slot="append">人</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="未实名认证数" prop="user.unauthenticatedCount">
                                    <el-input type="number" v-model="dialogData.user.unauthenticatedCount" :min="0" placeholder="注册但是没有完成实名认证">
                                        <template slot="append">人</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <!--<el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="邀请成功用户占总用户比例" prop="user.invitedUserRatio">
                                    <el-input type="number" v-model="dialogData.user.invitedUserRatio" :min="0" :step="0.01" placeholder="邀请成功用户占总用户比例">
                                        <template slot="append">%</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="发起邀请用户占总用户比例" prop="user.invitingUserRatio">
                                    <el-input type="number" v-model="dialogData.user.invitingUserRatio" :min="0" :step="0.01" placeholder="发起邀请用户占总用户比例">
                                        <template slot="append">%</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="被邀请注册占总注册用户比例" prop="user.invitedRegistrationRatio">
                                    <el-input type="number" v-model="dialogData.user.invitedRegistrationRatio" :min="0" :step="0.01" placeholder="被邀请注册占总注册用户比例">
                                        <template slot="append">%</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>-->
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="首骑用户数" prop="user.firstRiderCount">
                                    <el-tooltip content="首骑，不限于是否新注册用户（活动中心固定活动，点击完成有效骑行活动的详情可筛选时间查看）" placement="top-start">
                                        <el-input type="number" v-model="dialogData.user.firstRiderCount" :min="0" placeholder="首骑，不限于是否新注册用户">
                                            <template slot="append">人</template>
                                        </el-input>
                                    </el-tooltip>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="有效骑行用户数" prop="user.validRiderCount">
                                    <el-tooltip content="单日活跃且产生有效订单的用户去重（通过导出当日订单列表，筛选重复数据后得出）" placement="top-start">
                                        <el-input type="number" v-model="dialogData.user.validRiderCount" :min="0" placeholder="单日活跃且产生有效订单的用户去重">
                                            <template slot="append">人</template>
                                        </el-input>
                                    </el-tooltip>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="每日新增用户骑行转化率" prop="user.dailyNewRiderConversionRate">
                                    <el-tooltip content="当日新注册用户中完成有效骑行的比例（用当日新增用户和当日有效订单中的人名对比）" placement="top-start">
                                        <el-input type="number" v-model="dialogData.user.dailyNewRiderConversionRate" :min="0" :step="0.01" placeholder="当日新注册用户中完成有效骑行的比例">
                                            <template slot="append">%</template>
                                        </el-input>
                                    </el-tooltip>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="总用户的骑行转化率" prop="user.totalRiderConversionRate">
                                    <el-tooltip content="所有已注册用户完成有效骑行的比例（所有注册用户对比有骑行订单的用户）" placement="top-start">
                                        <el-input type="number" v-model="dialogData.user.totalRiderConversionRate" :min="0" :step="0.01" placeholder="所有已注册用户完成有效骑行的比例">
                                            <template slot="append">%</template>
                                        </el-input>
                                    </el-tooltip>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="活跃用户" prop="user.activeUserCount">
                                    <el-input type="number" v-model="dialogData.user.activeUserCount" :min="0" placeholder="7天内有1天骑行">
                                        <template slot="append">人</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="高活用户" prop="user.highActiveUserCount">
                                    <el-input type="number" v-model="dialogData.user.highActiveUserCount" :min="0" placeholder="7天内有4天骑行">
                                        <template slot="append">人</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="新增复活用户数" prop="user.newResurrectionCount">
                                    <el-input type="number" v-model="dialogData.user.newResurrectionCount" :min="0" placeholder="大于30天无骑行用户复骑">
                                        <template slot="append">人</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="新增流失用户数" prop="user.newLostCount">
                                    <el-input type="number" v-model="dialogData.user.newLostCount" :min="0" placeholder="新增的大于30天无订单用户">
                                        <template slot="append">人</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane :label="tagItem.vehicle" name="vehicle" v-if="dialogData.vehicle">
                        <el-row :gutter="10">
                            <el-card>
                                <div>
                                    <el-divider content-position="left">车辆周转率</el-divider>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="投放车周转率" prop="vehicle.vehicleTurnoverRatio">
                                            <el-tooltip content="有效订单/总投放车（剔除低于1分钟的订单后计算）" placement="top-start">
                                                <el-input type="number" v-model="dialogData.vehicle.vehicleTurnoverRatio" :min="0" :step="0.01" placeholder="有效订单/总投放车">
                                                    <template slot="append">%</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="可用车周转率" prop="vehicle.availableVehicleTurnoverRatio">
                                            <el-tooltip content="有效订单/总可用车（总可用车辆=可使用车辆+骑行中车辆）" placement="top-start">
                                                <el-input type="number" v-model="dialogData.vehicle.availableVehicleTurnoverRatio" :min="0" :step="0.01" placeholder="有效订单/总可用车">
                                                    <template slot="append">%</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="DAB周转率" prop="vehicle.vehicleDabTurnoverRatio">
                                            <el-tooltip content="有效订单/活跃可用车" placement="top-start">
                                                <el-input type="number" v-model="dialogData.vehicle.vehicleDabTurnoverRatio" :min="0" :step="0.01" placeholder="有效订单/活跃可用车">
                                                    <template slot="append">%</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                </div>
                            </el-card>

                            <el-card class="mrgt20">
                                <div>
                                    <el-divider content-position="left">投放车辆数据<span class="col_primary">（总投放数-可用车辆数-已下架数量=运维中车辆）</span></el-divider>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="总投放车" prop="vehicle.totalVehicleCount">
                                            <el-input type="number" v-model="dialogData.vehicle.totalVehicleCount" :min="0" placeholder="归属于该运营区的全部车辆">
                                                <template slot="append">辆</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="可用车辆" prop="vehicle.availableVehicleCount">
                                            <el-tooltip content="可以骑行的车辆，总投放数-非可用车辆数" placement="top-start">
                                                <el-input type="number" v-model="dialogData.vehicle.availableVehicleCount" :min="0" placeholder="可以骑行的车辆">
                                                    <template slot="append">辆</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="已下架数量" prop="vehicle.offlineVehicleCount">
                                            <el-input type="number" v-model="dialogData.vehicle.offlineVehicleCount" :min="0" placeholder="下架车辆的数量">
                                                <template slot="append">辆</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="运维中车辆" prop="maintenanceVehicleCount">
                                            <el-tooltip content="自动计算：运维中车辆=总投放数-可用车辆数-已下架数量" placement="top-start">
                                                <el-input type="number" v-model="maintenanceVehicleCount" disabled placeholder="运维中的车辆数量">
                                                    <template slot="append">辆</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="可用车辆占比" prop="availableVehicleRatio">
                                            <el-input type="number" v-model="availableVehicleRatio" disabled placeholder="可用车辆/总投放车">
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </div>
                            </el-card>

                            <el-card class="mrgt20">
                                <div>
                                    <el-divider content-position="left">运维中车辆数据<span class="col_primary">（已报修车辆+低电量车辆+其它状态的车辆）</span></el-divider>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="已报修车辆" prop="vehicle.reportedVehicleCount">
                                            <el-input type="number" v-model="dialogData.vehicle.reportedVehicleCount" :min="0" placeholder="已报修车辆数量">
                                                <template slot="append">辆</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="低电量车辆" prop="vehicle.lowBatteryVehicleCount">
                                            <el-input type="number" v-model="dialogData.vehicle.lowBatteryVehicleCount" :min="0" placeholder="低电量车辆数量">
                                                <template slot="append">辆</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="其它状态车辆" prop="vehicle.otherVehicleCount">
                                            <el-tooltip content="调度中车辆数+换电中车辆数+拖回中车辆数" placement="top-start">
                                                <el-input type="number" v-model="dialogData.vehicle.otherVehicleCount" :min="0" placeholder="其它状态的车辆数量">
                                                    <template slot="append">辆</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="已报修占比" prop="reportedVehicleRatio">
                                            <el-input type="number" v-model="reportedVehicleRatio" disabled placeholder="已报修车辆/总投放车">
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="低电量占比" prop="lowBatteryVehicleRatio">
                                            <el-input type="number" v-model="lowBatteryVehicleRatio" disabled placeholder="低电量车辆/总投放车">
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </div>
                            </el-card>

                            <el-card>
                                <div>
                                    <el-divider content-position="left">其他相关统计车辆数</el-divider>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="告警车辆" prop="vehicle.warningVehicleCount">
                                            <el-input type="number" v-model="dialogData.vehicle.warningVehicleCount" :min="0" placeholder="告警车辆数量">
                                                <template slot="append">辆</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="WAB车辆数" prop="vehicle.wabVehicleCount">
                                            <el-tooltip content="七日内活跃车辆数" placement="top-start">
                                                <el-input type="number" v-model="dialogData.vehicle.wabVehicleCount" :min="0" placeholder="七日内活跃车辆数">
                                                    <template slot="append">辆</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="电单车DAB" prop="vehicle.dabVehicleCount">
                                            <el-tooltip content="单日活跃车辆数" placement="top-start">
                                                <el-input type="number" v-model="dialogData.vehicle.dabVehicleCount" :min="0" placeholder="单日活跃车辆数">
                                                    <template slot="append">辆</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="未骑行2天未处理" prop="vehicle.day2IUVehicleCount">
                                            <el-tooltip content="进入未骑行后（48小时未产单）2天没有进行调度等激活车辆的动作" placement="top-start">
                                                <el-input type="number" v-model="dialogData.vehicle.day2IUVehicleCount" :min="0" placeholder="未骑行2天未处理车辆数">
                                                    <template slot="append">辆</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="7日未骑行车辆数" prop="vehicle.day7IUVehicleCount">
                                            <el-tooltip content="进入未骑行后（48小时未产单）7天没有进行调度等激活车辆的动作" placement="top-start">
                                                <el-input type="number" v-model="dialogData.vehicle.day7IUVehicleCount" :min="0" placeholder="7日未骑行车辆数">
                                                    <template slot="append">辆</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="未上报且2天未处理" prop="vehicle.day2UUVehicleCount">
                                            <el-tooltip content="未上报（2天无位置上报）后2天无商家端操作" placement="top-start">
                                                <el-input type="number" v-model="dialogData.vehicle.day2UUVehicleCount" :min="0" placeholder="未上报且2天未处理车辆数">
                                                    <template slot="append">辆</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="故障车2天未处理" prop="vehicle.day2UFVehicleCount">
                                            <el-input type="number" v-model="dialogData.vehicle.day2UFVehicleCount" :min="0" placeholder="故障车2天未处理车辆数">
                                                <template slot="append">辆</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="运营范围外2天未处理" prop="vehicle.day2UOSAVehicleCount">
                                            <el-input type="number" v-model="dialogData.vehicle.day2UOSAVehicleCount" :min="0" placeholder="骑出运营区域外后2天未处理车辆数">
                                                <template slot="append">辆</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </div>
                            </el-card>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane :label="tagItem.operation" name="operation" v-if="dialogData.operation">
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="换电量" prop="operation.batterySwapCount">
                                    <el-input type="number" v-model="dialogData.operation.batterySwapCount" :min="0" placeholder="单日换电次数">
                                        <template slot="append">次</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="挪车量" prop="operation.moveCount">
                                    <el-input type="number" v-model="dialogData.operation.moveCount" :min="0" placeholder="单日挪车次数">
                                        <template slot="append">次</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :xl="8">
                                <el-form-item label="维修量" prop="operation.maintenanceCount">
                                    <el-input type="number" v-model="dialogData.operation.maintenanceCount" :min="0" placeholder="单日维修次数">
                                        <template slot="append">次</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <span slot="footer">
                <el-button size="small" type="danger" v-if="dialogType == createOrUpdate[1]" @click="_updateAnsyc()">同步数据</el-button>
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">保存</el-button>
            </span>
        </el-dialog>

        <!--同步数据-->
        <sync-data :ref="refSyncData" @selected="syncSelectedData"></sync-data>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import * as funReport from "@/api/report"
import moment from "moment"
import SyncData from "./SyncData"
export default {
    name: "compAnalysisEdit",
    mixins: [configMixins],
    components: { SyncData },
    data() {
        return {
            refSyncData: "refEditToSyncData",
            activeName: "revenue",
            showDialog: false,
            dialogType: "", 
            dialogData: {},
            defaultDialogData: {
                id: 0,
                type: 0,
                area: "",
                weather: "",
                weatherExperience: "",
                minTemperature: null,
                maxTemperature: null,
                remark: "",
                recordingTime: "",
                order: {
                    totalOrderCount: null,
                    validOrderCount: null,
                    paidOrderCount: null,
                    averageOrderDuration: moment().format("YYYY-MM-DD 00:00:00"),
                    averageOrderAmount: null,
                    rideCardUsageRate: null,
                    memberCardUsageRate: null,
                    lowPowerLostOrderCount: null,
                    maintenanceLostOrderCount: null,
                    orderViolationRate: null,
                    saOrderViolationRate: null,
                    outOfSAOrderViolationRate: null
                },
                revenue: {
                    // totalRevenue: null,
                    walletRechargeAmount: null,
                    rideCardAmount: null,
                    memberCardAmount: null,
                    concessionCardAmount: null,
                    honestyAmount: null,
                    // giftAmount: null,
                    balanceGift: null,
                    rideCardGift: null,
                    memberCardGift: null,
                    concessionCardGift: null,
                    orderRideAmount: null,
                    orderPreferentialAmount: null,
                    orderPenaltyAmount: null,
                    orderAmount: null,
                    orderWalletPayAmount: null,
                    orderBalanceGiftPayAmount: null,
                    rideCardPreferential: null,
                    discountPreferential: null,
                    concessionCardPreferential: null,
                    otherPreferential: null,
                    walletRefund: null,
                    rechargeRefund: null,
                    giftRefund: null,
                    orderArrears: null,
                    historicalOrderWalletPayAmount: null,
                    historicalOrderGiftPayAmount: null
                },
                operation: {
                    batterySwapCount: null,
                    moveCount: null,
                    maintenanceCount: null
                },
                user: {
                    /* invitedUserRatio: null,
                    invitingUserRatio: null,
                    invitedRegistrationRatio: null, */
                    newRegistrationCount: null,
                    unauthenticatedCount: null,
                    firstRiderCount: null,
                    newResurrectionCount: null,
                    newLostCount: null,
                    validRiderCount: null,
                    activeUserCount: null,
                    highActiveUserCount: null,
                    dailyNewRiderConversionRate: null,
                    totalRiderConversionRate: null
                },
                vehicle: {
                    vehicleTurnoverRatio: null,
                    availableVehicleTurnoverRatio: null,
                    vehicleDabTurnoverRatio: null,
                    totalVehicleCount: null,
                    availableVehicleCount: null,
                    reportedVehicleCount: null,
                    lowBatteryVehicleCount: null,
                    offlineVehicleCount: null,
                    otherVehicleCount: null,
                    warningVehicleCount: null,
                    wabVehicleCount: null,
                    dabVehicleCount: null,
                    day2IUVehicleCount: null,
                    day7IUVehicleCount: null,
                    day2UUVehicleCount: null,
                    day2UFVehicleCount: null,
                    day2UOSAVehicleCount: null
                }
            },
            tagItem: {
                revenue: "营收数据",
                order: "订单数据",
                user: "用户数据",
                vehicle: "车辆状态",
                operation: "运维数据"
            },
            formRefName: "refAnalysisEdit",
            formRules: {
              date: [{ required: true, message: '请选择日期', trigger: ['blur', 'change']}],
              area: [{ required: true, message: '请选择运营地区', trigger: ['blur', 'change']}],
              weather: [{ required: true, message: '请选择天气情况', trigger: ['blur', 'change']}],
              weatherExperience: [{ required: true, message: '请选择天气体验感受', trigger: ['blur', 'change']}],
              minTemperature: [{ required: true, message: '请输入最低温度', trigger: 'blur'}],
              maxTemperature: [{ required: true, message: '请输入最高温度', trigger: 'blur'}],

              // "revenue.totalRevenue": [{ required: true, message: '请输入单日电车总收入', trigger: 'blur'}],
              "revenue.walletRechargeAmount": [{ required: true, message: '请输入钱包充值金额', trigger: 'blur'}],
              "revenue.rideCardAmount": [{ required: true, message: '请输入骑行卡金额', trigger: 'blur'}],
              "revenue.memberCardAmount": [{ required: true, message: '请输入会员卡金额', trigger: 'blur'}],
              "revenue.concessionCardAmount": [{ required: true, message: '请输入优惠卡金额', trigger: 'blur'}],
              "revenue.discountCardAmount": [{ required: true, message: '请输入优惠卡金额', trigger: 'blur'}],
              "revenue.honestyAmount": [{ required: true, message: '请输入诚信金额', trigger: 'blur'}],
              // "revenue.giftAmount": [{ required: true, message: '请输入赠送金额', trigger: 'blur'}],
              "revenue.balanceGift": [{ required: true, message: '请输入余额赠送', trigger: 'blur'}],
              "revenue.rideCardGift": [{ required: true, message: '请输入骑行卡赠送金额', trigger: 'blur'}],
              "revenue.memberCardGift": [{ required: true, message: '请输入会员卡赠送金额', trigger: 'blur'}],
              "revenue.concessionCardGift": [{ required: true, message: '请输入优惠卡赠送', trigger: 'blur'}],
              "revenue.orderRideAmount": [{ required: true, message: '请输入骑行费用', trigger: 'blur'}],
              "revenue.orderPreferentialAmount": [{ required: true, message: '请输入优惠金额', trigger: 'blur'}],
              "revenue.orderPenaltyAmount": [{ required: true, message: '请输入订单罚金', trigger: 'blur'}],
              "revenue.orderAmount": [{ required: true, message: '请输入订单实际金额', trigger: 'blur'}],
              "revenue.orderWalletPayAmount": [{ required: true, message: '请输入钱包结算金额', trigger: 'blur'}],
              "revenue.orderBalanceGiftPayAmount": [{ required: true, message: '请输入赠送余额结算', trigger: 'blur'}],
              "revenue.rideCardPreferential": [{ required: true, message: '请输入骑行卡优惠', trigger: 'blur'}],
              "revenue.discountPreferential": [{ required: true, message: '请输入折扣优惠', trigger: 'blur'}],
              "revenue.concessionCardPreferential": [{ required: true, message: '请输入优惠卡优惠', trigger: 'blur'}],
              "revenue.otherPreferential": [{ required: true, message: '请输入其它优惠', trigger: 'blur'}],
              "revenue.walletRefund": [{ required: true, message: '请输入钱包退款金额', trigger: 'blur'}],
              "revenue.rechargeRefund": [{ required: true, message: '请输入充值退款金额', trigger: 'blur'}],
              "revenue.giftRefund": [{ required: true, message: '请输入赠送退款金额', trigger: 'blur'}],
              "revenue.orderArrears": [{ required: true, message: '请输入订单欠款金额', trigger: 'blur'}],
              "revenue.historicalOrderWalletPayAmount": [{ required: true, message: '请输入历史订单钱包支付', trigger: 'blur'}],
              "revenue.historicalOrderGiftPayAmount": [{ required: true, message: '请输入历史订单赠送余额支付', trigger: 'blur'}],

              "order.totalOrderCount": [{ required: true, message: '请输入订单总数', trigger: 'blur'}],
              "order.validOrderCount": [{ required: true, message: '请输入有效订单数', trigger: 'blur'}],
              "order.paidOrderCount": [{ required: true, message: '请输入实收订单数', trigger: 'blur'}],
              "order.averageOrderDuration": [{ required: true, message: '请设置订单平均时长', trigger: 'blur'}],
              "order.averageOrderAmount": [{ required: true, message: '请输入订单平均金额', trigger: 'blur'}],
              "order.rideCardUsageRate": [{ required: true, message: '请输入骑行卡使用率', trigger: 'blur'}],
              "order.memberCardUsageRate": [{ required: true, message: '请输入会员卡使用率', trigger: 'blur'}],
              "order.lowPowerLostOrderCount": [{ required: true, message: '请输入电单车低电丢单次数', trigger: 'blur'}],
              "order.maintenanceLostOrderCount": [{ required: true, message: '请输入电单车运维丢单次数', trigger: 'blur'}],
              "order.orderViolationRate": [{ required: true, message: '请输入有效骑行订单违停率', trigger: 'blur'}],
              "order.saOrderViolationRate": [{ required: true, message: '请输入运营区内停车点外违停率', trigger: 'blur'}],
              "order.outOfSAOrderViolationRate": [{ required: true, message: '请输入运营区外违停率', trigger: 'blur'}],
              "user.newRegistrationCount": [{ required: true, message: '请输入新增注册数', trigger: 'blur'}],
              "user.unauthenticatedCount": [{ required: true, message: '请输入未实名认证数', trigger: 'blur'}],
              "user.firstRiderCount": [{ required: true, message: '请输入首骑用户数', trigger: 'blur'}],
              "user.newResurrectionCount": [{ required: true, message: '请输入新增复活用户数', trigger: 'blur'}],
              "user.newLostCount": [{ required: true, message: '请输入新增流失用户数', trigger: 'blur'}],
              "user.validRiderCount": [{ required: true, message: '请输入有效骑行用户数', trigger: 'blur'}],
              "user.activeUserCount": [{ required: true, message: '请输入活跃用户数', trigger: 'blur'}],
              "user.highActiveUserCount": [{ required: true, message: '请输入高活用户数', trigger: 'blur'}],
              "user.dailyNewRiderConversionRate": [{ required: true, message: '请输入每日新增用户骑行转化率', trigger: 'blur'}],
              "user.totalRiderConversionRate": [{ required: true, message: '请输入总用户的骑行转化率', trigger: 'blur'}],
            },
            // 对比同步数据的表
            syncTableData: [],
            isShowSyncBtn: false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            }
        }
    },
    computed: {
        rangeTemperature() {
            // 温度范围
            let val = ""
            if (this.dialogData.minTemperature && this.dialogData.maxTemperature) {
                val = `${this.dialogData.minTemperature}~${this.dialogData.maxTemperature}`
            } else {
                val = this.dialogData.minTemperature || this.dialogData.maxTemperature || ""
            }
            return val
        },
        validOrderRate() {
            // 有效订单率
            let val = null
            if (this.dialogData.order.totalOrderCount > 0) {
                val = (this.dialogData.order.validOrderCount || 0) / this.dialogData.order.totalOrderCount * 100
                val = window.$util.toFixed(val)
            }
            return val || null
        },
        paidOrderRate() {
            // 实收订单率
            let val = null
            if (this.dialogData.order.validOrderCount > 0) {
                val = (this.dialogData.order.paidOrderCount || 0) / this.dialogData.order.validOrderCount * 100
                val = window.$util.toFixed(val)
            }
            return val || null
        },
        availableVehicleRatio() {
            // 可用车辆占比
            let val = null
            if (this.dialogData.vehicle.totalVehicleCount > 0) {
                val = (this.dialogData.vehicle.availableVehicleCount || 0) / this.dialogData.vehicle.totalVehicleCount * 100
                val = window.$util.toFixed(val)
            }
            return val || null
        },
        reportedVehicleRatio() {
            // 报修车辆占比
            let val = null
            if (this.dialogData.vehicle.totalVehicleCount > 0) {
                val = (this.dialogData.vehicle.reportedVehicleCount || 0) / this.dialogData.vehicle.totalVehicleCount * 100
                val = window.$util.toFixed(val)
            }
            return val || null
        },
        lowBatteryVehicleRatio() {
            // 低电量车辆占比
            let val = null
            if (this.dialogData.vehicle.totalVehicleCount > 0) {
                val = (this.dialogData.vehicle.lowBatteryVehicleCount || 0) / this.dialogData.vehicle.totalVehicleCount * 100
                val = window.$util.toFixed(val)
            }
            return val || null
        },
        maintenanceVehicleCount() {
            // 运维中的车辆数量
            let val = null
            let totalVehicleCount = this.dialogData.vehicle.totalVehicleCount // 总投放车辆
            let availableVehicleCount = this.dialogData.vehicle.availableVehicleCount || 0 // 可用车辆
            let offlineVehicleCount = this.dialogData.vehicle.offlineVehicleCount || 0 // 已下架数量
            if (totalVehicleCount > 0) {
                val = totalVehicleCount - availableVehicleCount - offlineVehicleCount
            }
            return val
        }
    },
    methods: {
        open(row) {
            this.dialogData = JSON.parse(JSON.stringify(this.defaultDialogData))
            this.dialogData.area = this.$store.getters.serviceArea
            if (row && row.id) {
                this.dialogType = this.createOrUpdate[1]
                funReport.Detail(row.id).then(res => {
                    this.dialogData = this.tranceBaseData(res)
                    this._updateAnsyc(true)
                    this.showDialog = true
                })
                // 编辑
            } else {
                this.dialogType = this.createOrUpdate[0]
                this.showDialog = true
            }
        },
        tranceBaseData(data) {
            // 订单平均时长
            if (data.order && data.order.averageOrderDuration) {
                if (moment(data.order.averageOrderDuration).isValid()) {
                    data.order.averageOrderDuration = moment(data.order.averageOrderDuration).format("HH:mm:ss") || ""
                } else {
                    data.order.averageOrderDuration = moment().format("YYYY-MM-DD " + data.order.averageOrderDuration)
                }
            }
            /* if (data.order && data.order.averageOrderDuration) {
                data.order.averageOrderDuration = moment().format("YYYY-MM-DD " + data.order.averageOrderDuration)
            } */
            if (!isNaN(data.order.rideCardUsageRate) && data.order.rideCardUsageRate !== null) {
                // 骑行卡使用率
                data.order.rideCardUsageRate = window.$util.operationMulti(data.order.rideCardUsageRate, 100)
                data.order.rideCardUsageRate = window.$util.toFixed(data.order.rideCardUsageRate)
            }
            if (!isNaN(data.order.memberCardUsageRate) && data.order.memberCardUsageRate !== null) {
                // 会员卡使用率
                data.order.memberCardUsageRate = window.$util.operationMulti(data.order.memberCardUsageRate, 100)
                data.order.memberCardUsageRate = window.$util.toFixed(data.order.memberCardUsageRate)
            }
            if (!isNaN(data.order.orderViolationRate) && data.order.orderViolationRate !== null) {
                // 有效骑行订单违停率
                data.order.orderViolationRate = window.$util.operationMulti(data.order.orderViolationRate, 100)
                data.order.orderViolationRate = window.$util.toFixed(data.order.orderViolationRate)
            }
            if (!isNaN(data.order.saOrderViolationRate) && data.order.saOrderViolationRate !== null) {
                // 运营区内停车点外违停率
                data.order.saOrderViolationRate = window.$util.operationMulti(data.order.saOrderViolationRate, 100)
                data.order.saOrderViolationRate = window.$util.toFixed(data.order.saOrderViolationRate)
            }
            if (!isNaN(data.order.outOfSAOrderViolationRate) && data.order.outOfSAOrderViolationRate !== null) {
                // 运营区外违停率
                data.order.outOfSAOrderViolationRate = window.$util.operationMulti(data.order.outOfSAOrderViolationRate, 100)
                data.order.outOfSAOrderViolationRate = window.$util.toFixed(data.order.outOfSAOrderViolationRate)
            }
            if (!isNaN(data.user.dailyNewRiderConversionRate) && data.user.dailyNewRiderConversionRate !== null) {
                // 每日新增用户骑行转化率
                data.user.dailyNewRiderConversionRate = window.$util.operationMulti(data.user.dailyNewRiderConversionRate, 100)
                data.user.dailyNewRiderConversionRate = window.$util.toFixed(data.user.dailyNewRiderConversionRate)
            }
            if (!isNaN(data.user.totalRiderConversionRate) && data.user.totalRiderConversionRate !== null) {
                // 总用户的骑行转化率
                data.user.totalRiderConversionRate = window.$util.operationMulti(data.user.totalRiderConversionRate, 100)
                data.user.totalRiderConversionRate = window.$util.toFixed(data.user.totalRiderConversionRate)
            }
            if (!isNaN(data.vehicle.vehicleTurnoverRatio) && data.vehicle.vehicleTurnoverRatio !== null) {
                // 投放车周转率
                data.vehicle.vehicleTurnoverRatio = window.$util.operationMulti(data.vehicle.vehicleTurnoverRatio, 100)
                data.vehicle.vehicleTurnoverRatio = window.$util.toFixed(data.vehicle.vehicleTurnoverRatio)
            }
            if (!isNaN(data.vehicle.availableVehicleTurnoverRatio) && data.vehicle.availableVehicleTurnoverRatio !== null) {
                // 可用车周转率
                data.vehicle.availableVehicleTurnoverRatio = window.$util.operationMulti(data.vehicle.availableVehicleTurnoverRatio, 100)
                data.vehicle.availableVehicleTurnoverRatio = window.$util.toFixed(data.vehicle.availableVehicleTurnoverRatio)
            }
            if (!isNaN(data.vehicle.vehicleDabTurnoverRatio) && data.vehicle.vehicleDabTurnoverRatio !== null) {
                // 电单车DAB周转率
                data.vehicle.vehicleDabTurnoverRatio = window.$util.operationMulti(data.vehicle.vehicleDabTurnoverRatio, 100)
                data.vehicle.vehicleDabTurnoverRatio = window.$util.toFixed(data.vehicle.vehicleDabTurnoverRatio)
            }
            return data
        },
        // 选择时间和服务区域
        selectAndComputed(v) {
            if (this.dialogData.date && moment(this.dialogData.date).isValid() && this.dialogData.area) {
                let date = moment(this.dialogData.date).format("YYYY-MM-DD")
                let params = {
                    beginDate: date,
                    endDate: date,
                    area: this.dialogData.area
                }
                window.$common.fullLoading()
                funReport.ComputedValues(params).then(res => {
                    window.$common.closeFullLoading()
                    for (let i in this.dialogData) {
                        // json格式才执行
                        if (window.$util.isJsonFn(this.dialogData[i])) {
                            for (let oi in this.dialogData[i]) {
                                this.dialogData[i][oi] = res[i] && (res[i][oi] || res[i][oi] === 0) ? res[i][oi] : this.dialogData[i][oi] || null
                            }
                        } else {
                            this.dialogData[i] = res[i] || res[i] === 0 ? res[i] : (this.dialogData[i] || this.dialogData[i] === 0) ? this.dialogData[i] : null
                        }
                    }
                    // Object.assign(this.dialogData, res)
                    this.dialogData = this.tranceBaseData(this.dialogData)
                })
            }
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    // 其他状态车辆不能小于0，否则数据录入有错误
                    if (this.maintenanceVehicleCount < 0) {
                        this.errorMsg("投放车辆数据部分填写有误，计算结果与总数不符!")
                        return false
                    }
                    let commitData = JSON.parse(JSON.stringify(this.dialogData))
                    // 保存时转换数据
                    commitData = this.tranceCommitData(commitData)
                    window.$common.fullLoading()
                    if (this.dialogType == this.createOrUpdate[0]) {
                        // 创建
                        funReport.Create(commitData).then(() => {
                            this.commonSuccess("操作成功!")
                        })
                    } else {
                        // 修改
                        funReport.Update(commitData).then(() => {
                            this.commonSuccess("操作成功!")
                        })
                    }
                } else {
                    this.errorMsg("有数据未填写完整，请核对!")
                    return false
                }
            })
        },
        tranceCommitData(commitData) {
            if (commitData.date) {
                commitData.date = moment(commitData.date).format("YYYY-MM-DD")
            }
            if (commitData.order.averageOrderDuration) {
                // 订单平均时长
                commitData.order.averageOrderDuration = moment(commitData.order.averageOrderDuration).isValid()
                ? moment(commitData.order.averageOrderDuration).format("HH:mm:ss") : ""
            }
            if (!isNaN(commitData.order.rideCardUsageRate)) {
                // 骑行卡使用率
                commitData.order.rideCardUsageRate = window.$util.operationMulti(commitData.order.rideCardUsageRate, 100)
                commitData.order.rideCardUsageRate = window.$util.operationDiv(commitData.order.rideCardUsageRate, 10000)
            }
            if (!isNaN(commitData.order.memberCardUsageRate)) {
                // 会员卡使用率
                commitData.order.memberCardUsageRate = window.$util.operationMulti(commitData.order.memberCardUsageRate, 100)
                commitData.order.memberCardUsageRate = window.$util.operationDiv(commitData.order.memberCardUsageRate, 10000)
            }
            if (!isNaN(commitData.order.orderViolationRate)) {
                // 订单违停率
                commitData.order.orderViolationRate = window.$util.operationMulti(commitData.order.orderViolationRate, 100)
                commitData.order.orderViolationRate = window.$util.operationDiv(commitData.order.orderViolationRate, 10000)
            }
            if (!isNaN(commitData.order.saOrderViolationRate)) {
                // 运营区内订单违停率
                commitData.order.saOrderViolationRate = window.$util.operationMulti(commitData.order.saOrderViolationRate, 100)
                commitData.order.saOrderViolationRate = window.$util.operationDiv(commitData.order.saOrderViolationRate, 10000)
            }
            if (!isNaN(commitData.order.outOfSAOrderViolationRate)) {
                // 运营区外订单违停率
                commitData.order.outOfSAOrderViolationRate = window.$util.operationMulti(commitData.order.outOfSAOrderViolationRate, 100)
                commitData.order.outOfSAOrderViolationRate = window.$util.operationDiv(commitData.order.outOfSAOrderViolationRate, 10000)
            }
            if (!isNaN(commitData.user.dailyNewRiderConversionRate)) {
                // 每日新增用户骑行转化率
                commitData.user.dailyNewRiderConversionRate = window.$util.operationMulti(commitData.user.dailyNewRiderConversionRate, 100)
                commitData.user.dailyNewRiderConversionRate = window.$util.operationDiv(commitData.user.dailyNewRiderConversionRate, 10000)
            }
            if (!isNaN(commitData.user.totalRiderConversionRate)) {
                // 总用户的骑行转化率
                commitData.user.totalRiderConversionRate = window.$util.operationMulti(commitData.user.totalRiderConversionRate, 100)
                commitData.user.totalRiderConversionRate = window.$util.operationDiv(commitData.user.totalRiderConversionRate, 10000)
            }
            if (!isNaN(commitData.vehicle.vehicleTurnoverRatio)) {
                // 投放车周转率
                commitData.vehicle.vehicleTurnoverRatio = window.$util.operationMulti(commitData.vehicle.vehicleTurnoverRatio, 100)
                commitData.vehicle.vehicleTurnoverRatio = window.$util.operationDiv(commitData.vehicle.vehicleTurnoverRatio, 10000)
            }
            if (!isNaN(commitData.vehicle.availableVehicleTurnoverRatio)) {
                // 可用车周转率
                commitData.vehicle.availableVehicleTurnoverRatio = window.$util.operationMulti(commitData.vehicle.availableVehicleTurnoverRatio, 100)
                commitData.vehicle.availableVehicleTurnoverRatio = window.$util.operationDiv(commitData.vehicle.availableVehicleTurnoverRatio, 10000)
            }
            if (!isNaN(commitData.vehicle.vehicleDabTurnoverRatio)) {
                // 电单车DAB周转率
                commitData.vehicle.vehicleDabTurnoverRatio = window.$util.operationMulti(commitData.vehicle.vehicleDabTurnoverRatio, 100)
                commitData.vehicle.vehicleDabTurnoverRatio = window.$util.operationDiv(commitData.vehicle.vehicleDabTurnoverRatio, 10000)
            }
            return commitData
        },
        // 同步数据
        _updateAnsyc(isDefault) {
            if (this.dialogType == this.createOrUpdate[1] && this.dialogData.date && moment(this.dialogData.date).isValid() && this.dialogData.area) {
                let date = moment(this.dialogData.date).format("YYYY-MM-DD")
                let params = {
                    beginDate: date,
                    endDate: date,
                    area: this.dialogData.area
                }
                window.$common.fullLoading()
                funReport.ComputedValues(params).then(res => {
                    window.$common.closeFullLoading()
                    let tempData = JSON.parse(JSON.stringify(this.dialogData))
                    // tempData = this.tranceCommitData(tempData)
                    res = this.tranceBaseData(res)
                    // 获取哪些值不相同
                    let items = []
                    for (let i in tempData) {
                        if (window.$util.isJsonFn(tempData[i])) {
                            for (let ri in tempData[i]) {
                                if (res[i][ri] !== undefined && tempData[i][ri] != res[i][ri]) {
                                    items.push({
                                        key: `${i}.${ri}`,
                                        tag: this.tagItem[i],
                                        name: "",
                                        oldValue: tempData[i][ri],
                                        newValue: res[i][ri]
                                    })
                                }
                            }
                        }
                    }
                    if (items && items.length > 0) {
                        if (isDefault) {
                            this.isShowSyncBtn = true
                        } else {
                            this.getSyncTableData(items)
                        }
                    } else {
                        if (isDefault) {
                            this.isShowSyncBtn = false
                        } else {
                            this.warningMsg("没有可同步的数据!")
                        }
                    }
                })
            } else {
                if (!isDefault) {
                    this.warningMsg("请先选择日期和服务区!")
                }
                return false
            }
        },
        getSyncTableData(items) {
            // jquery遍历label，获取名称
            $("label").each((i, label) => {
                let key = $(label).prop("for")
                items = items.map(x => {
                    if (key && x.key === key) {
                        x.name = $(label).html()
                    }
                    return x
                })
            })
            this.syncTableData = items
            this.$refs[this.refSyncData].open(this.syncTableData)
        },
        // 更新同步来的数据
        syncSelectedData(items) {
            for (let i in items) {
                let keys = items[i].key.split(".")
                this.dialogData[keys[0]][keys[1]] = items[i].newValue
            }
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData()
            })
        },
        refreshData() {
            this.$emit("refreshData", this.dialogType === this.createOrUpdate[1] ? 1 : 0)
        }
    }
}
</script>