<style lang="scss" scoped>
/deep/ .sync-data-dialog {
    max-width: 650px;
}
</style>

<template>
    <div>
        <el-dialog title="同步数据" custom-class="c-el-dialog sync-data-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-table :data="tableData" border @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="分组名" prop="tag" width="120"></el-table-column>
                <el-table-column label="名称" prop="name" width="120"></el-table-column>
                <el-table-column label="原始值" prop="oldValue"></el-table-column>
                <el-table-column label="重新计算值" prop="newValue"></el-table-column>
                <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="_sync(scope.row)">同步</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer">
                <el-button size="small" @click="_close()">关闭</el-button>
                <el-button size="small" type="primary" @click="_save()">确认使用新值</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "compAnalysisSyncData",
    data() {
        return {
            showDialog: false,
            tableData: [],
            multipleSelection: []
        }
    },
    methods: {
        open(items) {
            this.tableData = items
            this.showDialog = true
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        _sync(row) {
            this.$emit("selected", [row])
            this.tableData = this.tableData.filter(x => {
                return x.key !== row.key
            })
        },
        _save() {
            if (this.multipleSelection.length <= 0) {
                this.warningMsg("请先选择需要同步的数据!")
                return false
            }
            this.successMsg("已同步到编辑界面，保存后生效!")
            this.$emit("selected", this.multipleSelection)
            this._close()
        },
        _close() {
            this.showDialog = false
        }
    }
}
</script>