<style lang="scss" scoped>
/deep/ .comp-analysis-detail-dialog {
    margin-top: 5vh !important;
    width: 88%;
    .el-tag {
        width: 100%;
        height: auto;
        min-height: 28px;
        white-space: normal;
        background-color: #ffffff;
        border-color: #DCDFE6;
    }
    .textareas {
        .el-tag {
            min-height: 80px;
        }
    }
    /deep/ jmnode {
        padding: 5px 10px;
    }
    /deep/ jmnode.root {
        font-size: 20px;
        background-color: #1b9aff;
        color: #ffffff;
        .jsmind-tips {
            color: #fff;
        }
    }
    /deep/ jmnode.selected {
        background-color: #1b9aff;
        .jsmind-tips {
            color: #fff;
        }
    }
    .jsmind-tips {
        margin-top: 3px;
        font-size: 13px;
        color: #666;
    }
}
</style>

<template>
    <div class="comp-analysis-detail">
        <el-dialog title="数据详情" custom-class="c-el-dialog comp-analysis-detail-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :ref="formRefName" label-width="232px" size="small">
                <el-tabs v-model="mainTab" @tab-click="handdleTabClick">
                    <el-tab-pane label="实收数据" name="first">
                        <div id="detailJsMind" style="width: 100%; height: 70vh;"></div>
                    </el-tab-pane>
                    <el-tab-pane label="报表详情" name="second">
                        <el-card>
                            <div slot="header">
                                <span>综合信息</span>
                            </div>
                            <el-row :gutter="10">
                                <el-col :xs="24" :sm="12" :xl="8">
                                    <el-form-item label="日期">
                                        <el-tag type="info">
                                            {{ getFormatDate(dialogData.date, false, "YYYY-MM-DD") }}
                                            ({{ getFormatWeek(dialogData.date) }})
                                        </el-tag>
                                    </el-form-item>
                                </el-col>
                                <el-col :xs="24" :sm="12" :xl="8">
                                    <el-form-item label="投放地区">
                                        <el-tag type="info">{{ dialogData.area }}</el-tag>
                                    </el-form-item>
                                </el-col>
                                <el-col :xs="24" :sm="12" :xl="8">
                                    <el-form-item label="天气">
                                        <el-tag type="info">{{ dialogData.weather }}</el-tag>
                                    </el-form-item>
                                </el-col>
                                <el-col :xs="24" :sm="12" :xl="8">
                                    <el-form-item label="天气体验">
                                        <el-tag type="info">{{ dialogData.weatherExperience }}</el-tag>
                                    </el-form-item>
                                </el-col>
                                <el-col :xs="24" :sm="12" :xl="8">
                                    <el-form-item label="最低温度">
                                        <el-input v-model="dialogData.minTemperature" readonly>
                                            <template slot="append">℃</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :xs="24" :sm="12" :xl="8">
                                    <el-form-item label="最高温度">
                                        <el-input v-model="dialogData.maxTemperature" readonly>
                                            <template slot="append">℃</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :xs="24" :sm="12" :xl="8">
                                    <el-form-item label="温度范围">
                                        <el-input v-model="rangeTemperature" readonly>
                                            <template slot="append">℃</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-card>
                        <el-tabs class="mrgt20" v-model="activeName" type="border-card">
                            <el-tab-pane label="营收数据" name="revenue" v-if="dialogData.revenue">
                                <el-row :gutter="10">
                                    <el-card>
                                        <div>
                                            <el-divider content-position="left">营收</el-divider>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="总收入">
                                                    <el-tooltip content="单日电车总收入（净入账=钱包充值-钱包退款+骑行卡收入+会员卡收入+优惠卡收入+诚信金收入）" placement="top-start">
                                                        <el-input v-model="dialogData.revenue.totalRevenue" readonly>
                                                            <template slot="append">元</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="钱包充值金额">
                                                    <el-input v-model="dialogData.revenue.walletRechargeAmount" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="骑行卡金额">
                                                    <el-input v-model="dialogData.revenue.rideCardAmount" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="会员卡金额">
                                                    <el-input v-model="dialogData.revenue.memberCardAmount" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="优惠卡金额">
                                                    <el-input v-model="dialogData.revenue.concessionCardAmount" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="诚信金额">
                                                    <el-input v-model="dialogData.revenue.honestyAmount" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="钱包退款">
                                                    <el-tooltip content="钱包退款：从钱包退还给用户" placement="top-start">
                                                        <el-input v-model="dialogData.revenue.walletRefund" readonly>
                                                            <template slot="append">元</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="充值退款">
                                                    <el-tooltip content="充值退款：退回余额" placement="top-start">
                                                        <el-input v-model="dialogData.revenue.rechargeRefund" readonly>
                                                            <template slot="append">元</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                        </div>
                                    </el-card>

                                    <el-card class="mrgt20">
                                        <div>
                                            <el-divider content-position="left">实收</el-divider>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="骑行费用">
                                                    <el-input v-model="dialogData.revenue.orderRideAmount" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="优惠金额">
                                                    <el-tooltip content="优惠金额=骑行卡优惠+折扣优惠+优惠卡优惠+其它优惠" placement="top-start">
                                                        <el-input v-model="dialogData.revenue.orderPreferentialAmount" readonly>
                                                            <template slot="append">元</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="订单罚金">
                                                    <el-input v-model="dialogData.revenue.orderPenaltyAmount" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="订单实际金额">
                                                    <el-tooltip content="订单实际金额(骑行金额-优惠金额+罚金)" placement="top-start">
                                                        <el-input v-model="dialogData.revenue.orderAmount" readonly>
                                                            <template slot="append">元</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="钱包结算金额">
                                                    <el-input v-model="dialogData.revenue.orderWalletPayAmount" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="赠送余额结算">
                                                    <el-input v-model="dialogData.revenue.orderBalanceGiftPayAmount" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="骑行卡优惠">
                                                    <el-input v-model="dialogData.revenue.rideCardPreferential" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="折扣优惠">
                                                    <el-input v-model="dialogData.revenue.discountPreferential" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="优惠卡优惠">
                                                    <el-input v-model="dialogData.revenue.concessionCardPreferential" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="其它优惠">
                                                    <el-tooltip content="其它优惠=优惠金额-骑行卡优惠-折扣优惠-优惠卡优惠" placement="top-start">
                                                        <el-input v-model="dialogData.revenue.otherPreferential" readonly>
                                                            <template slot="append">元</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                        </div>
                                    </el-card>

                                    <el-card class="mrgt20">
                                        <div>
                                            <el-divider content-position="left">订单收支</el-divider>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="订单应收金额">
                                                    <el-input v-model="dialogData.revenue.receivableAmount" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="订单欠款金额">
                                                    <el-tooltip content="截至到订单表格导入时，还未支付的金额" placement="top-start">
                                                        <el-input v-model="dialogData.revenue.orderArrears" readonly>
                                                            <template slot="append">元</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="历史订单钱包支付">
                                                    <el-tooltip content="历史订单中使用钱包支付的金额" placement="top-start">
                                                        <el-input v-model="dialogData.revenue.historicalOrderWalletPayAmount" readonly>
                                                            <template slot="append">元</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="历史订单赠送余额支付">
                                                    <el-tooltip content="历史订单中使用赠送余额支付的金额" placement="top-start">
                                                        <el-input v-model="dialogData.revenue.historicalOrderGiftPayAmount" readonly>
                                                            <template slot="append">元</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                        </div>
                                    </el-card>

                                    <el-card class="mrgt20">
                                        <div>
                                            <el-divider content-position="left">赠送</el-divider>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="赠送金额">
                                                    <el-tooltip content="赠送金额=余额赠送+骑行卡赠送+会员卡赠送+优惠卡赠送" placement="top-start">
                                                        <el-input v-model="dialogData.revenue.giftAmount" readonly>
                                                            <template slot="append">元</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="余额赠送">
                                                    <el-input v-model="dialogData.revenue.balanceGift" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="骑行卡赠送">
                                                    <el-input v-model="dialogData.revenue.rideCardGift" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="会员卡赠送">
                                                    <el-input v-model="dialogData.revenue.memberCardGift" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="优惠卡赠送">
                                                    <el-input v-model="dialogData.revenue.concessionCardGift" readonly>
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="赠送退款">
                                                    <el-tooltip content="赠送退款：退回到赠送余额" placement="top-start">
                                                        <el-input v-model="dialogData.revenue.giftRefund" readonly>
                                                            <template slot="append">元</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                        </div>
                                    </el-card>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="订单数据" name="order" v-if="dialogData.order">
                                <el-row :gutter="10">
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="订单总数">
                                            <el-input v-model="dialogData.order.totalOrderCount" readonly></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="有效订单数">
                                            <el-input v-model="dialogData.order.validOrderCount" readonly></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="有效订单率">
                                            <el-input v-model="validOrderRate" readonly>
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="实收订单数">
                                            <el-input v-model="dialogData.order.paidOrderCount" readonly></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="实收订单率">
                                            <el-tooltip content="已完成骑行并正常支付的订单比例（实收/有效）" placement="top-start">
                                                <el-input v-model="paidOrderRate" readonly>
                                                    <template slot="append">%</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="订单平均时长">
                                            <el-tooltip content="有效订单数量平均时间（排除超过1小时的长订单，计算所有订单的平均时间）" placement="top-start">
                                                <el-input v-model="dialogData.order.averageOrderDuration" readonly></el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="订单平均金额">
                                            <el-tooltip content="实收订单数量平均金额，实收金额/实收订单" placement="top-start">
                                                <el-input v-model="dialogData.order.averageOrderAmount" readonly>
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="骑行卡使用率">
                                            <el-input v-model="dialogData.order.rideCardUsageRate" readonly>
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="会员卡使用率">
                                            <el-input v-model="dialogData.order.memberCardUsageRate" readonly>
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="电单车低电丢单次数">
                                            <el-input v-model="dialogData.order.lowPowerLostOrderCount" readonly>
                                                <template slot="append">次</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="电单车运维丢单次数">
                                            <el-input v-model="dialogData.order.maintenanceLostOrderCount" readonly>
                                                <template slot="append">次</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="有效骑行订单违停率">
                                            <el-input v-model="dialogData.order.orderViolationRate" readonly>
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="运营区内停车点外违停率">
                                            <el-tooltip content="点位外还车订单/（总订单-运营区外还车订单）" placement="top-start">
                                                <el-input v-model="dialogData.order.saOrderViolationRate" readonly>
                                                    <template slot="append">%</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="运营区外违停率">
                                            <el-tooltip content="运营区外还车订单/（总订单-运营区内点位外还车订单）" placement="top-start">
                                                <el-input v-model="dialogData.order.outOfSAOrderViolationRate" readonly>
                                                    <template slot="append">%</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="用户数据" name="user" v-if="dialogData.user">
                                <el-row :gutter="10">
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="新增注册数">
                                            <el-input v-model="dialogData.user.newRegistrationCount" readonly>
                                                <template slot="append">人</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="未实名认证数">
                                            <el-input v-model="dialogData.user.unauthenticatedCount" readonly>
                                                <template slot="append">人</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <!--<el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="邀请成功用户占总用户比例">
                                            <el-input v-model="dialogData.user.invitedUserRatio" readonly>
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="发起邀请用户占总用户比例">
                                            <el-input v-model="dialogData.user.invitingUserRatio" readonly>
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="被邀请注册占总注册用户比例">
                                            <el-input v-model="dialogData.user.invitedRegistrationRatio" readonly>
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>-->
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="首骑用户数">
                                            <el-tooltip content="首骑，不限于是否新注册用户（活动中心固定活动，点击完成有效骑行活动的详情可筛选时间查看）" placement="top-start">
                                                <el-input v-model="dialogData.user.firstRiderCount" readonly>
                                                    <template slot="append">人</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="有效骑行用户数">
                                            <el-tooltip content="单日活跃且产生有效订单的用户去重（通过导出当日订单列表，筛选重复数据后得出）" placement="top-start">
                                                <el-input v-model="dialogData.user.validRiderCount" readonly>
                                                    <template slot="append">人</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="每日新增用户骑行转化率">
                                            <el-tooltip content="当日新注册用户中完成有效骑行的比例（用当日新增用户和当日有效订单中的人名对比）" placement="top-start">
                                                <el-input v-model="dialogData.user.dailyNewRiderConversionRate" readonly>
                                                    <template slot="append">%</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="总用户的骑行转化率">
                                            <el-tooltip content="所有已注册用户完成有效骑行的比例（所有注册用户对比有骑行订单的用户）" placement="top-start">
                                                <el-input v-model="dialogData.user.totalRiderConversionRate" readonly>
                                                    <template slot="append">%</template>
                                                </el-input>
                                            </el-tooltip>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="活跃用户">
                                            <el-input v-model="dialogData.user.activeUserCount" readonly>
                                                <template slot="append">人</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="高活用户">
                                            <el-input v-model="dialogData.user.highActiveUserCount" readonly>
                                                <template slot="append">人</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="新增复活用户数">
                                            <el-input v-model="dialogData.user.newResurrectionCount" readonly>
                                                <template slot="append">人</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="新增流失用户数">
                                            <el-input v-model="dialogData.user.newLostCount" readonly>
                                                <template slot="append">人</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="车辆状态" name="vehicle" v-if="dialogData.vehicle">
                                <el-row :gutter="10">
                                    <el-card>
                                        <div>
                                            <el-divider content-position="left">车辆周转率</el-divider>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="投放车周转率">
                                                    <el-tooltip content="有效订单/总投放车（剔除低于1分钟的订单后计算）" placement="top-start">
                                                        <el-input v-model="dialogData.vehicle.vehicleTurnoverRatio" readonly>
                                                            <template slot="append">%</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="可用车周转率">
                                                    <el-tooltip content="有效订单/总可用车（总可用车辆=可使用车辆+骑行中车辆）" placement="top-start">
                                                        <el-input v-model="dialogData.vehicle.availableVehicleTurnoverRatio" readonly>
                                                            <template slot="append">%</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="DAB周转率">
                                                    <el-tooltip content="有效订单/活跃可用车" placement="top-start">
                                                        <el-input v-model="dialogData.vehicle.vehicleDabTurnoverRatio" readonly>
                                                            <template slot="append">%</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                        </div>
                                    </el-card>

                                    <el-card class="mrgt20">
                                        <div>
                                            <el-divider content-position="left">投放车辆数据<span class="col_primary">（总投放数-可用车辆数-已下架数量=运维中车辆）</span></el-divider>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="总投放车">
                                                    <el-input v-model="dialogData.vehicle.totalVehicleCount" readonly>
                                                        <template slot="append">辆</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="可用车辆">
                                                    <el-tooltip content="可以骑行的车辆，总投放数-非可用车辆数" placement="top-start">
                                                        <el-input v-model="dialogData.vehicle.availableVehicleCount" readonly>
                                                            <template slot="append">辆</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="已下架数量">
                                                    <el-input v-model="dialogData.vehicle.offlineVehicleCount" readonly>
                                                        <template slot="append">辆</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="运维中车辆">
                                                    <el-tooltip content="运维中车辆=总投放数-可用车辆数-已下架数量" placement="top-start">
                                                        <el-input v-model="dialogData.vehicle.maintenanceVehicleCount" readonly>
                                                            <template slot="append">辆</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="可用车辆占比">
                                                    <el-input v-model="availableVehicleRatio" readonly>
                                                        <template slot="append">%</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                        </div>
                                    </el-card>

                                    <el-card class="mrgt20">
                                        <div>
                                            <el-divider content-position="left">运维中车辆数据<span class="col_primary">（已报修车辆+低电量车辆+其它状态的车辆）</span></el-divider>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="已报修车辆">
                                                    <el-input v-model="dialogData.vehicle.reportedVehicleCount" readonly>
                                                        <template slot="append">辆</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="低电量车辆">
                                                    <el-input v-model="dialogData.vehicle.lowBatteryVehicleCount" readonly>
                                                        <template slot="append">辆</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="其它状态车辆">
                                                    <el-tooltip content="调度中车辆数+换电中车辆数+拖回中车辆数" placement="top-start">
                                                        <el-input v-model="otherVehicleCount" readonly>
                                                            <template slot="append">辆</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="已报修占比">
                                                    <el-input v-model="reportedVehicleRatio" readonly>
                                                        <template slot="append">%</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="低电量占比">
                                                    <el-input v-model="lowBatteryVehicleRatio" readonly>
                                                        <template slot="append">%</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                        </div>
                                    </el-card>

                                    <el-card class="mrgt20">
                                        <div>
                                            <el-divider content-position="left">其他相关统计车辆数</el-divider>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="告警车辆">
                                                    <el-input v-model="dialogData.vehicle.warningVehicleCount" readonly>
                                                        <template slot="append">辆</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="WAB车辆数">
                                                    <el-tooltip content="七日内活跃车辆数" placement="top-start">
                                                        <el-input v-model="dialogData.vehicle.wabVehicleCount" readonly>
                                                            <template slot="append">辆</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="电单车DAB">
                                                    <el-tooltip content="单日活跃车辆数" placement="top-start">
                                                        <el-input v-model="dialogData.vehicle.dabVehicleCount" readonly>
                                                            <template slot="append">辆</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="未骑行2天未处理">
                                                    <el-tooltip content="进入未骑行后（48小时未产单）2天没有进行调度等激活车辆的动作" placement="top-start">
                                                        <el-input v-model="dialogData.vehicle.day2IUVehicleCount" readonly>
                                                            <template slot="append">辆</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="7日未骑行车辆数">
                                                    <el-tooltip content="进入未骑行后（48小时未产单）7天没有进行调度等激活车辆的动作" placement="top-start">
                                                        <el-input v-model="dialogData.vehicle.day7IUVehicleCount" readonly>
                                                            <template slot="append">辆</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="未上报且2天未处理">
                                                    <el-tooltip content="未上报（2天无位置上报）后2天无商家端操作" placement="top-start">
                                                        <el-input v-model="dialogData.vehicle.day2UUVehicleCount" readonly>
                                                            <template slot="append">辆</template>
                                                        </el-input>
                                                    </el-tooltip>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="故障车2天未处理">
                                                    <el-input v-model="dialogData.vehicle.day2UFVehicleCount" readonly>
                                                        <template slot="append">辆</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :xs="24" :sm="12" :xl="8">
                                                <el-form-item label="运营范围外2天未处理">
                                                    <el-input v-model="dialogData.vehicle.day2UOSAVehicleCount" readonly>
                                                        <template slot="append">辆</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                        </div>
                                    </el-card>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="运维数据" name="operation" v-if="dialogData.operation">
                                <el-row :gutter="10">
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="换电量">
                                            <el-input v-model="dialogData.operation.batterySwapCount" readonly>
                                                <template slot="append">次</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="挪车量">
                                            <el-input v-model="dialogData.operation.moveCount" readonly>
                                                <template slot="append">次</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :xl="8">
                                        <el-form-item label="维修量">
                                            <el-input v-model="dialogData.operation.maintenanceCount" readonly>
                                                <template slot="append">次</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                        </el-tabs>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import * as funReport from "@/api/report"
import "jsmind/style/jsmind.css"
import jsMind from "jsmind/js/jsmind"
require("jsmind/js/jsmind.draggable-node.js")
export default {
    name: "compAnalysisDetail",
    mixins: [configMixins],
    data() {
        return {
            mainTab: "first",
            activeName: "revenue",
            showDialog: false,
            dialogData: {},
            defaultDialogData: {
                id: 0,
                type: 0,
                area: "",
                weather: "",
                weatherExperience: "",
                minTemperature: null,
                maxTemperature: null,
                remark: "",
                recordingTime: "",
                order: {
                    totalOrderCount: null,
                    validOrderCount: null,
                    paidOrderCount: null,
                    averageOrderDuration: "",
                    averageOrderAmount: null,
                    rideCardUsageRate: null,
                    memberCardUsageRate: null,
                    lowPowerLostOrderCount: null,
                    maintenanceLostOrderCount: null,
                    orderViolationRate: null,
                    saOrderViolationRate: null,
                    outOfSAOrderViolationRate: null
                },
                revenue: {
                    totalRevenue: null,
                    totalRideRevenue: null,
                    nonRideRevenue: null,
                    walletRechargeAmount: null,
                    rideCardAmount: null,
                    memberCardAmount: null,
                    discountCardAmount: null,
                    honestyAmount: null,
                    giftAmount: null,
                    refundAmount: null,
                    rechargeRefund: null,
                    giftRefund: null,
                    orderArrears: null,
                    historicalOrderWalletPayAmount: null,
                    historicalOrderGiftPayAmount: null
                },
                operation: {
                    batterySwapCount: null,
                    moveCount: null,
                    maintenanceCount: null
                },
                user: {
                    /* invitedUserRatio: null,
                    invitingUserRatio: null,
                    invitedRegistrationRatio: null, */
                    newRegistrationCount: null,
                    unauthenticatedCount: null,
                    firstRiderCount: null,
                    newResurrectionCount: null,
                    newLostCount: null,
                    validRiderCount: null,
                    activeUserCount: null,
                    highActiveUserCount: null,
                    dailyNewRiderConversionRate: null,
                    totalRiderConversionRate: null
                },
                vehicle: {
                    vehicleTurnoverRatio: null,
                    availableVehicleTurnoverRatio: null,
                    vehicleDabTurnoverRatio: null,
                    totalVehicleCount: null,
                    availableVehicleCount: null,
                    reportedVehicleCount: null,
                    lowBatteryVehicleCount: null,
                    offlineVehicleCount: null,
                    otherVehicleCount: null,
                    wabVehicleCount: null,
                    dabVehicleCount: null,
                    day2IUVehicleCount: null,
                    day7IUVehicleCount: null,
                    day2UUVehicleCount: null,
                    day2UFVehicleCount: null,
                    day2UOSAVehicleCount: null
                }
            },
            formRefName: "refAnalysisDetail",
            objMind: null,
            options: {
                container: "detailJsMind", // [必选] 容器的ID，或者为容器的对象
                editable: false,                // [可选] 是否启用编辑
                theme: "default",
                mode: "side",
                view: {
                    draggable: true
                }
            },
            mindDatas: {
                meta: {
                    name: "实收数据"
                },
                format: "node_tree",
                data:{}
            }
        }
    },
    computed: {
        rangeTemperature() {
            // 温度范围
            let val = ""
            if (this.dialogData.minTemperature && this.dialogData.maxTemperature) {
                val = `${this.dialogData.minTemperature}~${this.dialogData.maxTemperature}`
            } else {
                val = this.dialogData.minTemperature || this.dialogData.maxTemperature || ""
            }
            return val
        },
        validOrderRate() {
            // 有效订单率
            let val = null
            if (this.dialogData.order.totalOrderCount > 0) {
                val = (this.dialogData.order.validOrderCount || 0) / this.dialogData.order.totalOrderCount * 100
                val = window.$util.toFixed(val)
            }
            return val || null
        },
        paidOrderRate() {
            // 实收订单率
            let val = null
            if (this.dialogData.order.validOrderCount > 0) {
                val = (this.dialogData.order.paidOrderCount || 0) / this.dialogData.order.validOrderCount * 100
                val = window.$util.toFixed(val)
            }
            return val || null
        },
        availableVehicleRatio() {
            // 可用车辆占比
            let val = null
            if (this.dialogData.vehicle.totalVehicleCount > 0) {
                val = (this.dialogData.vehicle.availableVehicleCount || 0) / this.dialogData.vehicle.totalVehicleCount * 100
                val = window.$util.toFixed(val)
            }
            return val || null
        },
        reportedVehicleRatio() {
            // 报修车辆占比
            let val = null
            if (this.dialogData.vehicle.totalVehicleCount > 0) {
                val = (this.dialogData.vehicle.reportedVehicleCount || 0) / this.dialogData.vehicle.totalVehicleCount * 100
                val = window.$util.toFixed(val)
            }
            return val || null
        },
        lowBatteryVehicleRatio() {
            // 低电量车辆占比
            let val = null
            if (this.dialogData.vehicle.totalVehicleCount > 0) {
                val = (this.dialogData.vehicle.lowBatteryVehicleCount || 0) / this.dialogData.vehicle.totalVehicleCount * 100
                val = window.$util.toFixed(val)
            }
            return val || null
        },
        otherVehicleCount() {
            // 其他状态车辆数量
            let val = null
            let totalVehicleCount = this.dialogData.vehicle.totalVehicleCount // 总投放车辆
            let availableVehicleCount = this.dialogData.vehicle.availableVehicleCount || 0 // 可用车辆
            let reportedVehicleCount = this.dialogData.vehicle.reportedVehicleCount || 0 // 报修车辆
            let lowBatteryVehicleCount = this.dialogData.vehicle.lowBatteryVehicleCount || 0 // 低电量车辆
            let offlineVehicleCount = this.dialogData.vehicle.offlineVehicleCount || 0 // 已下架数量
            if (totalVehicleCount > 0) {
                val = totalVehicleCount - availableVehicleCount - reportedVehicleCount - lowBatteryVehicleCount - offlineVehicleCount
            }
            return val
        }
    },
    mounted() {},
    methods: {
        open(row) {
            this.dialogData = JSON.parse(JSON.stringify(this.defaultDialogData))
            this.dialogData.area = this.$store.getters.serviceAreas[0] && this.$store.getters.serviceAreas[0].name
            funReport.Detail(row.id).then(res => {
                this.dialogData = res
                // 骑行卡使用率
                this.dialogData.order.rideCardUsageRate = window.$util.operationMulti(this.dialogData.order.rideCardUsageRate, 100)
                // 会员卡使用率
                this.dialogData.order.memberCardUsageRate = window.$util.operationMulti(this.dialogData.order.memberCardUsageRate, 100)
                // 订单违停率
                this.dialogData.order.orderViolationRate = window.$util.operationMulti(this.dialogData.order.orderViolationRate, 100)
                // 运营区内订单违停率
                this.dialogData.order.saOrderViolationRate = window.$util.operationMulti(this.dialogData.order.saOrderViolationRate, 100)
                // 运营区外订单违停率
                this.dialogData.order.outOfSAOrderViolationRate = window.$util.operationMulti(this.dialogData.order.outOfSAOrderViolationRate, 100)
                // 每日新增用户骑行转化率
                this.dialogData.user.dailyNewRiderConversionRate = window.$util.operationMulti(this.dialogData.user.dailyNewRiderConversionRate, 100)
                // 总用户的骑行转化率
                this.dialogData.user.totalRiderConversionRate = window.$util.operationMulti(this.dialogData.user.totalRiderConversionRate, 100)
                // 投放车周转率
                this.dialogData.vehicle.vehicleTurnoverRatio = window.$util.operationMulti(this.dialogData.vehicle.vehicleTurnoverRatio, 100)
                // 可用车周转率
                this.dialogData.vehicle.availableVehicleTurnoverRatio = window.$util.operationMulti(this.dialogData.vehicle.availableVehicleTurnoverRatio, 100)
                // 电单车DAB周转率
                this.dialogData.vehicle.vehicleDabTurnoverRatio = window.$util.operationMulti(this.dialogData.vehicle.vehicleDabTurnoverRatio, 100)
                this.showDialog = true
                // 显示脑图
                this.showJsMind()
            })
        },
        handdleTabClick() {
            this.showJsMind()
        },
        showJsMind() {
            this.$nextTick(() => {
                let data = this.dialogData.revenue
                this.mindDatas.data = {
                    id: "theoryOfRevenue",
                    topic: `￥${data.theoryOfRevenue}<div class='jsmind-tips'>理论收入</div>`,
                    children: [
                        {
                            id: "actualRevenue",
                            topic: `￥${data.actualRevenue}<div class='jsmind-tips'>实际收入</div>`,
                            expanded: true,
                            children: [
                                {
                                    id: "orderWalletPayAmount",
                                    topic: `￥${data.orderWalletPayAmount}<div class='jsmind-tips'>钱包支付</div>`,
                                    expanded: false,
                                    children: [
                                        {
                                            id: "rideActualAmount",
                                            topic: `￥${data.rideActualAmount}<div class='jsmind-tips'>骑行实收</div>`
                                        },
                                        {
                                            id: "orderPenaltyAmount",
                                            topic: `￥${data.orderPenaltyAmount}<div class='jsmind-tips'>订单罚金</div>`
                                        }
                                    ]
                                },
                                {
                                    id: "walletRefund",
                                    topic: `￥${data.walletRefund > 0 ? "-" + data.walletRefund : data.walletRefund}<div class='jsmind-tips'>钱包退款</div>`
                                },
                                {
                                    id: "rideCardAmount",
                                    topic: `￥${data.rideCardAmount}<div class='jsmind-tips'>骑行卡收入</div>`
                                },
                                {
                                    id: "memberCardAmount",
                                    topic: `￥${data.memberCardAmount}<div class='jsmind-tips'>会员卡收入</div>`
                                },
                                {
                                    id: "concessionCardAmount",
                                    topic: `￥${data.concessionCardAmount}<div class='jsmind-tips'>优惠卡收入</div>`
                                },
                            ]
                        }, {
                            id: "actualPreferential",
                            topic: `￥${data.actualPreferential}<div class='jsmind-tips'>优惠金额</div>`,
                            expanded: true,
                            children: [
                                {
                                    id: "orderPreferentialAmount",
                                    topic: `￥${data.orderPreferentialAmount}<div class='jsmind-tips'>订单优惠</div>`,
                                    expanded: false,
                                    children: [
                                        {
                                            id: "rideCardPreferential",
                                            topic: `￥${data.rideCardPreferential}<div class='jsmind-tips'>骑行卡优惠</div>`
                                        },
                                        {
                                            id: "discountPreferential",
                                            topic: `￥${data.discountPreferential}<div class='jsmind-tips'>折扣优惠</div>`
                                        },
                                        {
                                            id: "concessionCardPreferential",
                                            topic: `￥${data.concessionCardPreferential}<div class='jsmind-tips'>优惠卡优惠</div>`
                                        },
                                        {
                                            id: "otherPreferential",
                                            topic: `￥${data.otherPreferential}<div class='jsmind-tips'>其它优惠</div>`
                                        },
                                    ]
                                },
                                {
                                    id: "orderBalanceGiftPayAmount",
                                    topic: `￥${data.orderBalanceGiftPayAmount}<div class='jsmind-tips'>赠送支付</div>`
                                }
                            ]
                        },
                    ]
                }
                if (this.objMind) {
                    this.objMind.show(this.mindDatas)
                    this.$forceUpdate()
                } else {
                    this.objMind = new jsMind(this.options)
                    this.objMind.show(this.mindDatas)
                }
            })
        },
        _close() {
            this.showDialog = false
        }
    }
}
</script>